<template>
  <div class="card flex-fill">
    <div class="card-header d-flex align-items-center ">
      <button class="btn btn-secondary btn-icon btn-minimal btn-sm text-white d-xl-none mr-2" @click="closeMain">
        <i class="iconfont icon-back"></i>
      </button>
      <div class="text-white">表情包管理</div>
    </div>
    <div class="card-body hide-scrollbar">
      <button type="button" class="btn btn-primary mb-3" @click="addGif" style="display:block">
        添加表情包
      </button>

      <div class="imgBlock mr-2 mt-2" v-for="data in emojiList" :key="data.emoji_id">
        <div :style="'background:url(' + data.path + ')'"></div>
        <!-- <input type="text" placeholder="关键字" class="form-control search " v-model="data.kw" @change="upEmoji(data)" /> -->
        <button type="button" class="btn btn-primary btn-sm mb-2 mt-2" @click="upEmoji(data)" style="margin-left:2px">修改</button>
        <button type="button" class="btn btn-danger btn-sm" @click="delEmoji(data.emoji_id)">删除</button>
      </div>
    </div>
    <AddGIF v-if="GifBool" @chooseApp="chooseApp" />
    <UpdateGif v-if="upGifBool" @closeUp="closeUp" :EmojiObj="EmojiObj" />
  </div>
</template>

<script>
import AddGIF from "@/components/modal/addGif";
import UpdateGif from "@/components/modal/updateGif";
import { getEmojiList, editEmojiList } from "@/api/set";
import eventBus from "@/utils/eventBus";

export default {
  components: {
    AddGIF,
    UpdateGif
  },
  created() {
    this.EmojiList();
  },
  data() {
    return {
      GifBool: false,
      emojiList: [],
      URL: process.env.VUE_APP_API_BASE_URL,
      EmojiObj: {
        kw: ""
      },
      upGifBool: false
    };
  },
  methods: {
    closeMain() {
      eventBus.$emit("mainSwitch", false);
    },
    addGif() {
      this.GifBool = true;
      // this.$refs.uploadFile.click();
    },
    addUrl(url) {
      if (url.indexOf("/") == 0) {
        return this.URL + url;
      }
      return url;
    },
    chooseApp(data) {
      console.log(data);
      if (data == "success") {
        this.EmojiList();
      }
      this.GifBool = !this.GifBool;
    },
    closeUp(data) {
      console.log(data);
      if (data == "success") {
        this.EmojiList();
      }
      this.upGifBool = !this.upGifBool;
    },
    // 获取表情包列表
    EmojiList() {
      getEmojiList({ perpage: 40, page: 1, app_id: localStorage.getItem("app_id") }).then((res) => {
        res.result.forEach((element) => {
          element.path = this.addUrl(element.path);
        });
        this.emojiList = res.result;
        console.log(this.emojiList);
      });
    },
    // 删除表情包
    delEmoji(emoji_id) {
      editEmojiList({ emoji_id }).then((res) => {
        if (res.code == 200) {
          this.EmojiList();
          this.$toast.success("表情包删除成功", {
            timeout: 4000,
            position: "top-right"
          });
        }
        console.log(res);
      });
    },
    upEmoji(data) {
      this.EmojiObj = JSON.parse(JSON.stringify(data));
      this.upGifBool = true;
    }
  }
};
</script>

<style lang="less"></style>
