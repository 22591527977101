<template>
  <div id="app">
    <Layout v-if="$route.meta.inDashboard" />
    <Login v-if="$route.name == 'login'" />
  </div>
</template>
<script>
import Layout from "@/components/Layout.vue";
import Login from "@/views/login.vue";

export default {
  name: "Home",
  components: {
    Layout,
    Login
  },
  mounted: function() {
    window.document.addEventListener("drop", function(e) {
      //拖离
      e.preventDefault();
    });
    window.document.addEventListener("dragleave", function(e) {
      //拖后放
      e.preventDefault();
    });
    window.document.addEventListener("dragenter", function(e) {
      //拖进
      e.preventDefault();
    });
    window.document.addEventListener("dragover", function(e) {
      //拖来拖去
      e.preventDefault();
    });
  }
};
</script>
