<template
  ><div>
    <div class="modal modal-lg-fullscreen fade" :class="{ show: modalShow, 'd-none': !modalDis, 'd-block': modalDis }" @click="close">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom">
        <div class="modal-content " @click.stop="void 0">
          <div class="modal-header">
            <h5 class="modal-title">修改</h5>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12 d-flex flex-column align-items-center justify-content-center">
                <img :src="EmojiObj.path" draggable="false" class="emojiImg" />
                <input type="text" placeholder="关键字" class="form-control search w-50 mt-2" v-model="EmojiObj.kw" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary " style="display: block;" @click="sure">确认</button>
            <button type="button" class="btn btn-danger " style="display: block;" @click="close">取消</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: modalShow, 'd-none': !modalDis }"></div>
  </div>
</template>
<script>
import md5 from "md5";
import { saveEmojiKw } from "@/api/set";

export default {
  data() {
    this.md5 = md5;
    return {
      modalDis: true,
      modalShow: false
    };
  },
  props: {
    EmojiObj: Object
  },
  mounted() {
    this.modalDis = true;
    setTimeout(() => {
      this.modalShow = true;
    }, 100);
  },
  methods: {
    sure() {
      saveEmojiKw({ kw: this.EmojiObj.kw, emoji_id: this.EmojiObj.emoji_id }).then((res) => {
        if (res.code == 200) {
          this.$toast.success("修改成功", {
            timeout: 4000,
            position: "top-right"
          });
          this.modalShow = false;
          setTimeout(() => {
            this.$emit("closeUp", "success");
          }, 300);
        }
      });
    },
    close: function() {
      this.modalShow = false;
      setTimeout(() => {
        this.$emit("closeUp");
      }, 300);
    }
  }
};
</script>

<style scoped>
.emojiImg {
  width: 6rem;
  height: 6rem;
  border-radius: 0.4rem;
}
</style>
