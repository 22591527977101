<template
  ><div>
    <div class="modal modal-lg-fullscreen fade" :class="{ show: modalShow, 'd-none': !modalDis, 'd-block': modalDis }" @click="close">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom">
        <div class="modal-content" @click.stop="void 0">
          <div class="modal-header">
            <h5 class="modal-title">添加快捷输入</h5>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-0 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div class="toast-body">
                  <div class="form-group form-row">
                    <label class="col-sm-2 col-form-label text-center">快捷内容</label>
                    <div class="col">
                      <input type="email" class="form-control text-white" placeholder="请输入快捷内容" v-model="fields" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="sure">确认</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: modalShow, 'd-none': !modalDis }"></div>
  </div>
</template>
<script>
import { addQuickList } from "@/api/set";
export default {
  data() {
    return {
      modalDis: true,
      modalShow: false,
      fields: ""
    };
  },

  mounted() {
    this.modalDis = true;
    setTimeout(() => {
      this.modalShow = true;
    }, 100);
  },
  methods: {
    sure() {
      addQuickList({ app_id: localStorage.getItem("app_id"), fields: this.fields }).then((res) => {
        if (res.code == 200) {
          this.fields = "";
          this.modalShow = false;
          setTimeout(() => {
            this.$emit("closeApp", "success");
          }, 300);
        }
        console.log(res);
      });
    },
    close: function() {
      this.modalShow = false;
      setTimeout(() => {
        this.$emit("closeApp");
      }, 300);
    }
  }
};
</script>
