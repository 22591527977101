<template>
  <div class="card flex-fill select-none">
    <div class="card-header d-flex align-items-center">
      <button class="btn btn-secondary btn-icon btn-minimal btn-sm text-white d-xl-none mr-2" @click="closeMain">
        <i class="iconfont icon-back"></i>
      </button>
      <div class="text-white">系统设置</div>
    </div>
    <div class="card-body d-flex justify-content-center hide-scrollbar">
      <div style="width:70%">
        <div class=" text-white">通用设置</div>
        <hr />
        <div class="form-group form-row">
          <label class="col-sm-2 col-form-label text-left">静态资源地址</label>
          <div class="col">
            <input type="email" class="form-control text-white" placeholder="请输入静态资源地址" v-model="appObj.js_path" />
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-sm-2 col-form-label text-left">附件地址</label>
          <div class="col">
            <input type="email" class="form-control text-white" placeholder="请输入附件地址" v-model="appObj.attachment_path" />
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-sm-2 col-form-label text-left">单页聊天地址</label>
          <div class="col">
            <input type="email" class="form-control text-white" placeholder="请输入单页聊天地址" v-model="appObj.chat_url" />
          </div>
        </div>
        <hr />
        <div class=" text-white">附件设置</div>
        <hr />
        <div class="form-group form-row">
          <label class="col-sm-2 col-form-label text-left">过期时间</label>
          <div class="col input-group">
            <input type="email" class="form-control text-white" placeholder="请输入天数" v-model="appObj.attachment_expire_days" />
            <div class="input-group-append">
              <span class="input-group-text text-muted" id="basic-addon2" style="padding:0.275rem 0.75rem;">天</span>
            </div>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-sm-2 col-form-label text-left">附件大小</label>
          <div class="col input-group">
            <input type="email" class="form-control text-white" placeholder="请输入附件大小" v-model="appObj.file_size" />
            <div class="input-group-append">
              <span class="input-group-text text-muted" id="basic-addon2" style="padding:0.275rem 0.75rem;">MB</span>
            </div>
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-sm-2 col-form-label text-left">OSS</label>
          <div class="col">
            <div class="form-check mb-0 d-flex aligin-items-center mt-1 pl-0">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="appObj.upload_mode" />
                <label class="custom-control-label" for="customSwitch1">点击切换为{{ appObj.upload_mode ? "本地存储" : "OSS存储" }}</label>
              </div>
            </div>
          </div>
        </div>
        <template v-if="appObj.upload_mode">
          <div class="form-group form-row">
            <label class="col-sm-2 col-form-label text-left">accessKeyId</label>
            <div class="col">
              <input type="email" class="form-control text-white" placeholder="请输入accessKeyId" v-model="appObj.ali_oss.accessKeyId" />
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-sm-2 col-form-label text-left">accessKeySecret</label>
            <div class="col">
              <input type="email" class="form-control text-white" placeholder="请输入accessKeySecret" v-model="appObj.ali_oss.accessKeySecret" />
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-sm-2 col-form-label text-left">bucket</label>
            <div class="col">
              <input type="email" class="form-control text-white" placeholder="请输入bucket" v-model="appObj.ali_oss.bucket" />
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-sm-2 col-form-label text-left">endpoint</label>
            <div class="col">
              <input type="email" class="form-control text-white" placeholder="请输入endpoint" v-model="appObj.ali_oss.endpoint" />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="form-group form-row">
            <label class="col-sm-2 col-form-label text-left">存储路径</label>
            <div class="col">
              <input type="email" class="form-control text-white" placeholder="请输入绝对路径，如：/home/upload" v-model="appObj.store_path" />
            </div>
          </div>
        </template>
        <div style="margin-top:10rem">
          <button type="button" class="btn btn-primary " @click="update">确认修改</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
import { getSystemConfig, setSystemConfig } from "@/api/set";
export default {
  created() {
    this.getList();
  },
  data() {
    return {
      appObj: {},
    };
  },
  methods: {
    closeMain() {
      eventBus.$emit("mainSwitch", false);
    },
    getList() {
      getSystemConfig().then((res) => {
        res.result.upload_mode = res.result.upload_mode == "1" ? true : false;
        console.log(JSON.stringify(res.result));
        if (res.result.ali_oss == undefined) {
          res.result.ali_oss = {
            accessKeyId: "",
            accessKeySecret: "",
            bucket: "",
            endpoint: "",
          };
        }
        this.appObj = res.result;
      });
    },
    update() {
      var obj = Object.assign({}, this.appObj);
      obj.attachment_expire_days = String(this.appObj.attachment_expire_days);
      this.appObj.upload_mode == true ? (obj.upload_mode = "1") : (obj.upload_mode = "0");
      setSystemConfig(obj).then((res) => {
        if (res.code != 200) {
          return;
        }
        console.log(res.code);
        if (res.code == 200) {
          this.$toast.success("修改成功", {
            timeout: 4000,
            position: "top-right",
          });
          this.getList();
        }
      });
    },
  },
};
</script>

<style lang="less">
.select-none {
  user-select: none;
  input {
    user-select: text !important;
  }
}
</style>
