import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import './permission' // permission control
import "@/components/scss/app.scss"; // global style
import "@/components/scss/skins/dark-skin.scss"; // global style
import VueSocketIO from "vue-socket.io";

import { VueAxios } from "./utils/request";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// import VModal from "vue-js-modal";

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: process.env.VUE_APP_API_BASE_URL + "/seat",
    options: {
      autoConnect: false,
      query: { token: "" }
    }
  })
);

Vue.use(VueAxios);
Vue.use(Toast);
// Vue.use(VModal);

Vue.config.productionTip = false;

var vue = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");

export default vue;
