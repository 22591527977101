<template>
  <div class="card flex-fill">
    <div class="card-header d-flex align-items-center">
      <button class="btn btn-secondary btn-icon btn-minimal btn-sm text-white d-xl-none mr-2" @click="closeMain">
        <i class="iconfont icon-back"></i>
      </button>
      <div class="text-white">快捷输入设置</div>
    </div>
    <div class="card-body">
      <button type="button" class="btn btn-primary mb-3" @click="showQuick">添加快捷输入</button>
      <table class="table table-striped text-center">
        <thead>
          <tr class="text-white">
            <th scope="col">内容</th>
            <th scope="col">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-white" v-for="data in quickList" :key="data.id">
            <td>{{ data.fields }}</td>
            <td>
              <button type="button" class="btn btn-danger btn-sm" @click="del(data.id)">
                删除
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddQuick v-if="quickBool" @closeApp="closeApp" />
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
import { getQuickList, delQuickList } from "@/api/set";
import AddQuick from "@/components/modal/addQuick";
export default {
  components: {
    AddQuick
  },
  created() {
    this.getList();
  },
  data() {
    return {
      quickBool: false,
      quickList: []
    };
  },
  methods: {
    del(id) {
      delQuickList({ app_id: localStorage.getItem("app_id"), id }).then((res) => {
        if (res.code == 200) {
          this.$toast.success("快捷消息删除成功", {
            timeout: 4000,
            position: "top-right"
          });
          this.getList();
        }
      });
    },
    getList() {
      getQuickList({ app_id: localStorage.getItem("app_id") }).then((res) => {
        this.quickList = res.result;
      });
    },
    closeMain() {
      eventBus.$emit("mainSwitch", false);
    },
    showQuick() {
      console.log(123);
      this.quickBool = true;
    },
    closeApp(data) {
      if (data == "success") {
        this.$toast.success("快捷消息添加成功", {
          timeout: 4000,
          position: "top-right"
        });
        this.getList();
      }
      this.quickBool = !this.quickBool;
    }
  }
};
</script>
