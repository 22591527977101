<template>
  <div class="set w-100 h-100 d-flex">
    <welcome v-if="path == ''" :title="'请选择左侧管理开始操作'" />
    <!-- 个人设置 -->
    <User v-if="path == 'user'" />
    <!-- 表情包管理 -->
    <Emoji v-if="path == 'emoji'" />
    <!-- 应用管理 -->
    <Application v-if="path == 'application'" />
    <!-- 快捷设置 -->
    <Quick v-if="path == 'quick'" />
    <!-- 常见问题 -->
    <Problem v-if="path == 'problem'" />
    <!-- 系统设置 -->
    <System v-if="path == 'system'" />
  </div>
</template>
<script>
import eventBus from "@/utils/eventBus";
import welcome from "@/components/welcome";

import Emoji from "@/components/setting/emoji";
import User from "@/components/setting/user";
import Application from "@/components/setting/application";
import Quick from "@/components/setting/quick";
import System from "@/components/setting/system";
import Problem from "@/components/setting/problem";

export default {
  data() {
    return {
      path: "",
      maskBool: false,
      URL: process.env.VUE_APP_API_BASE_URL,
      emojiList: [],
      appLists: [],
      modalTitle: "添加应用",
      htmlBool: false,
      html: "",
      GifBool: false
    };
  },
  components: {
    welcome,
    Emoji,
    User,
    Application,
    Quick,
    System,
    Problem
  },

  created() {
    eventBus.$on("clickSet", (data) => {
      console.log(data.path);
      this.path = data.path;
      eventBus.$emit("mainSwitch", true);
    });
  },

  methods: {
    addGIf() {
      this.GifBool = !this.GifBool;
    },

    addUrl(url) {
      if (url.indexOf("/") == 0) {
        return this.URL + url;
      }
      return url;
    },

    closeMain() {
      eventBus.$emit("mainSwitch", false);
    }
  }
};
</script>

<style lang="less">
.imgBlock {
  width: 7rem;
  height: 7rem;
  position: relative;
  display: inline-block;
  text-align: center;
}

.imgBlock > div {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;
  transform-style: preserve-3d;
  transform: translateZ(100px);
}

.imgBlock > div::before {
  content: "" !important;
  background-color: #2a2a2a !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 1 !important;
  animation-name: cc-seb1 !important;
  animation-duration: 1.5s !important;
  animation-timing-function: ease-in-out !important;
  animation-iteration-count: infinite !important;
  border-radius: 10px;
}

@keyframes cc-seb1 {
  0%,
  100% {
    opacity: 1;
    transform: translateZ(-100px);
  }
  50% {
    opacity: 0.5;
    transform: translateZ(-100px);
  }
}

.imgBlock .form-control {
  caret-color: #adb5bd;
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}
.imgBlock img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 0.5rem;
}
.imgBlock-close {
  width: 1rem;
  height: 1rem;
  background: #ff337c;
  position: absolute;
  right: -0.3rem;
  top: -0.3rem;
  border-radius: 1rem;
  text-align: center;
  line-height: 0.8rem;
  font-size: 1rem;
  user-select: none;
  cursor: pointer;
  color: white;
}

.welcome-title {
  width: 300px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
}

.mask-aleart {
  width: var(--my-w);
  max-height: 70%;
  overflow: auto;
  background: #323333;
  border-radius: 0.2rem;
  .toast-header {
    background: none;
  }
  .toast-body {
    min-height: 50vh;
  }
}

.mask-aleart::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.toast-footer {
  border-top: 1px solid #2b2b2f;
  justify-content: flex-end;
}
.w-e-toolbar,
.w-e-text-container {
  background: #2a2a2a !important;
}
.toolbar {
  border: 1px solid #ccc;
  border-bottom: 0px;
  width: 100%;
  overflow: auto;
}
.w-e-text {
  color: white;
}
.text {
  width: 100%;
  height: 250px;
  border: 1px solid #ccc;
}
.out {
  margin-top: 35%;
  justify-content: flex-end;
}
</style>
