import request from "@/utils/request";

const expenseApi = {
    clientList: "/crm/clientList",
    clientChats: "/crm/clientChats"

};


// crm列表
export function clientList(parameter) {
    return request({
        url: expenseApi.clientList,
        method: "get",
        params: parameter
    });
}
export function getClientChats(parameter) {
    return request({
        url: expenseApi.clientChats,
        method: "get",
        params: parameter
    });
}
