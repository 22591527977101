<template>
  <div class="main-layout" :class="{ 'modal-open': modalOpen }">
    <div class="navigation navbar navbar-light bg-primary">
      <!-- Logo Start -->
      <a class="d-none d-xl-block bg-light rounded p-1" @click="chooseApp" style="cursor: pointer;">
        <svg v-if="!app_icon" height="30" width="30" viewBox="0 0 512 511">
          <g>
            <path
              d="m120.65625 512.476562c-7.25 0-14.445312-2.023437-20.761719-6.007812-10.929687-6.902344-17.703125-18.734375-18.117187-31.660156l-1.261719-41.390625c-51.90625-46.542969-80.515625-111.890625-80.515625-183.992188 0-68.816406 26.378906-132.101562 74.269531-178.199219 47.390625-45.609374 111.929688-70.726562 181.730469-70.726562s134.339844 25.117188 181.730469 70.726562c47.890625 46.097657 74.269531 109.382813 74.269531 178.199219 0 68.8125-26.378906 132.097657-74.269531 178.195313-47.390625 45.609375-111.929688 70.730468-181.730469 70.730468-25.164062 0-49.789062-3.253906-73.195312-9.667968l-46.464844 20.5c-5.035156 2.207031-10.371094 3.292968-15.683594 3.292968zm135.34375-471.976562c-123.140625 0-216 89.816406-216 208.925781 0 60.667969 23.957031 115.511719 67.457031 154.425781 8.023438 7.226563 12.628907 17.015626 13.015625 27.609376l.003906.125 1.234376 40.332031 45.300781-19.988281c8.15625-3.589844 17.355469-4.28125 25.921875-1.945313 20.132812 5.554687 41.332031 8.363281 63.066406 8.363281 123.140625 0 216-89.816406 216-208.921875 0-119.109375-92.859375-208.925781-216-208.925781zm-125.863281 290.628906 74.746093-57.628906c5.050782-3.789062 12.003907-3.839844 17.101563-.046875l55.308594 42.992187c16.578125 12.371094 40.304687 8.007813 51.355469-9.433593l69.519531-110.242188c6.714843-10.523437-6.335938-22.417969-16.292969-14.882812l-74.710938 56.613281c-5.050781 3.792969-12.003906 3.839844-17.101562.046875l-55.308594-41.988281c-16.578125-12.371094-40.304687-8.011719-51.355468 9.429687l-69.554688 110.253907c-6.714844 10.523437 6.335938 22.421874 16.292969 14.886718zm0 0"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#665dfe"
            />
          </g>
        </svg>
        <img v-else class="avatar avatar-square avatar-sm" :src="app_icon" alt="" />
      </a>
      <!-- Logo End -->

      <!-- Main Nav Start -->
      <ul class="nav nav-minimal flex-row flex-grow-1 justify-content-between flex-xl-column justify-content-xl-center" id="mainNavTab" role="tablist">
        <!-- Chats Tab Start -->
        <li class="nav-item">
          <Router-link class="nav-link p-0 py-xl-3" id="chats-tab" :to="{ path: '/' }" title="会话" :class="{ active: $route.meta.menu == 'chat' }">
            <i class="iconfont icon-chat"></i>
          </Router-link>
        </li>
        <!-- Chats Tab End -->

        <!-- Calls Tab Start -->
        <li class="nav-item" v-if="rules.seat && rules.seat.read == 1">
          <Router-link class="nav-link p-0 py-xl-3" id="calls-tab" :to="{ path: '/seats' }" title="坐席" :class="{ active: $route.meta.menu == 'seats' }">
            <i class="iconfont icon-seat"></i>
          </Router-link>
        </li>
        <!-- Calls Tab End -->

        <!-- Friends Tab Start -->
        <li class="nav-item" v-if="rules.crm && rules.crm.read == 1">
          <Router-link class="nav-link p-0 py-xl-3" id="friends-tab" :to="{ path: '/crm' }" title="客户" :class="{ active: $route.meta.menu == 'crm' }">
            <i class="iconfont icon-client"></i>
          </Router-link>
        </li>
        <!-- Friends Tab End -->

        <!-- Profile Tab Start -->
        <li class="nav-item">
          <Router-link class="nav-link p-0 py-xl-3" id="profile-tab" :to="{ path: '/settings' }" title="设置" :class="{ active: $route.meta.menu == 'settings' }">
            <i class="iconfont icon-settings"></i>
          </Router-link>
        </li>
        <!-- Profile Tab End -->
      </ul>
      <!-- Main Nav End -->
      <div class="network-info d-none d-none d-xl-block" :class="{ 'text-success': ping < 100, 'text-danger': ping >= 300, 'text-warning': ping >= 100 && ping < 300 }">
        <i class="iconfont icon-wifi"></i>
        <p>{{ ping }} ms</p>
      </div>
    </div>
    <!-- Navigation End -->
    <!-- <router-view /> -->
    <ChooseApp v-if="modalChooseApp" @chooseApp="chooseApp" />
    <SiderBar />
    <Main />
    <audio controls="controls" hidden src="@/assets/media/ding.mp3" ref="audio_ding"></audio>
  </div>
</template>

<script>
let rules = {
  app: { add: 0, edit: 0, read: 0, del: 0 },
  set: { add: 0, edit: 0, read: 0, del: 0 },
  emoji: { add: 0, edit: 0, read: 0, del: 0 },
  fastReply: { add: 0, edit: 0, read: 0, del: 0 },
  problem: { add: 0, edit: 0, read: 0, del: 0 },
  crm: { add: 0, edit: 0, read: 0, del: 0 },
  seat: { add: 0, edit: 0, read: 0, del: 0 }
};
// @ is an alias to /src
import SiderBar from "@/components/SiderBar.vue";
import Main from "@/components/Main.vue";
import ChooseApp from "@/components/modal/chooseApp.vue";
import { getSeatInfo } from "@/api/basic";
import eventBus from "@/utils/eventBus";

export default {
  name: "Home",
  components: {
    SiderBar,
    Main,
    ChooseApp
  },
  data() {
    return {
      ping: 1000,
      pingTime: 0,
      disToast: -1,
      modalChooseApp: false,
      modalOpen: false,
      app_icon: null,
      rules: {}
    };
  },
  created() {
    const app_id = localStorage.getItem("app_id");
    getSeatInfo({ app_id }).then((res) => {
      if (res.result.seat_id == 1) {
        for (let key in rules) {
          rules[key].add = 1;
          rules[key].edit = 1;
          rules[key].read = 1;
          rules[key].del = 1;
        }
        if (!app_id) {
          rules["seat"] = { add: 0, edit: 0, read: 0, del: 0 };
          rules["crm"] = { add: 0, edit: 0, read: 0, del: 0 };
        }
      }
      // let obj = {};
      // rules.forEach((v) => {
      //   for (let key in v) {
      //     if (key == "priv") {
      //       obj[v[key]] = v;
      //     }
      //   }
      // });
      this.rules = res.result.priv ? res.result.priv : rules;
      console.log(this.rules);
      this.$store.commit("setUserInfo", res.result);
    });
  },
  mounted() {
    let token = localStorage.getItem("token");
    let app_id = localStorage.getItem("app_id");
    let app_icon = localStorage.getItem("app_icon");
    if (app_icon) {
      this.app_icon = app_icon;
    }
    this.$socket.io.opts.query.token = token;
    this.$socket.io.opts.query.app_id = app_id;
    this.$socket.open();
    let that = this;
    setInterval(function() {
      that.getPing();
    }, 1e3);
    if (!app_id) {
      this.modalChooseApp = true;
    }

    eventBus.$on("audio", (type) => {
      this.audio(type);
    });
  },
  methods: {
    audio(type) {
      this.$refs[`audio_${type}`].currentTime = 0; //从头开始播放提示音
      this.$refs[`audio_${type}`].play();
    },
    getPing() {
      if (Date.now() - this.pingTime > 10 * 1e3) {
        this.ping = 1000;
        this.pingTime = Date.now();
        this.$socket.emit("getPing", this.pingTime);
      }
    },
    chooseApp() {
      this.modalChooseApp = !this.modalChooseApp;
    },
    toggleModal() {
      this.modalOpen = !this.modalOpen;
    }
  },
  sockets: {
    connect: function() {
      console.log("ws connected");
      let that = this;
      setTimeout(function() {
        that.getPing();
      }, 1e3);
      if (this.disToast > -1) {
        this.$toast.update(this.disToast, {
          closeButton: true,
          timeout: 3000,
          content: "连接已恢复",
          closeOnClick: true
        });
        const toastId = this.disToast;
        setTimeout(() => {
          that.$toast.dismiss(toastId);
        }, 1e3 * 3);
        this.disToast = -1;
      }

      this.$socket.emit("getSeatOnlineStatus", 2);
    },
    disconnect: function() {
      this.ping = 1000;
      this.disToast = this.$toast.error("连接丢失，正在重新连接", {
        closeButton: false,
        timeout: 0,
        position: "top-right",
        closeOnClick: false
      });
      // console.log(this.$toast);
      // console.log(disToast);
    },
    tokenError: function() {
      this.$toast.error("登录状态出错，请重新登录", {
        timeout: 4000,
        position: "top-right"
      });
      localStorage.removeItem("token");
      this.$router.push({ path: "/login" });
    },
    getPing: function(ping) {
      this.ping = Date.now() - ping;
      this.getPing();
    },
    testObj: function(obj) {
      console.log(obj);
    },
    reciveMessage: function(data) {
      if (this.$store.state.current_session_id != data.session_id) {
        this.audio("ding");
      }
    }
  }
};
</script>

<style scoped>
.iconfont {
  font-size: 24px;
}
</style>
