import request from "@/utils/request";

const expenseApi = {
  sessionList: "/seat/sessionList",
  messageList: "/seat/messageList",
  vip: "/seat/setVIP",
  ok: "/seat/setSolved",
  memo: "/seat/setMemo",
  sendFile: "/seat/sendFile",
  msgRecord: "/seat/msgRecord",
  sessInfo: "/seat/sessionInfo",
  sessionList2: "/seat/sessionPage",
  block: "/seat/toBlock",
  kv: "/seat/upKvData"
};

export function getSessionList(parameter) {
  return request({
    url: expenseApi.sessionList,
    method: "get",
    params: parameter
  });
}

export function getMessageList(parameter) {
  return request({
    url: expenseApi.messageList,
    method: "get",
    params: parameter
  });
}

export function setVip(parameter) {
  return request({
    url: expenseApi.vip,
    method: "post",
    data: parameter
  });
}

export function over(parameter) {
  return request({
    url: expenseApi.ok,
    method: "post",
    data: parameter
  });
}

export function serMemo(parameter) {
  return request({
    url: expenseApi.memo,
    method: "post",
    data: parameter
  });
}
// 上传附件
export function SendFile(parameter) {
  return request({
    url: expenseApi.sendFile,
    method: "post",
    data: parameter
  });
}


export function getMsgRecord(parameter) {
  return request({
    url: expenseApi.msgRecord,
    method: "get",
    params: parameter
  });
}
export function getSessInfo(parameter) {
  return request({
    url: expenseApi.sessInfo,
    method: "get",
    params: parameter
  });
}
// 分页
export function getSessionList2(parameter) {
  return request({
    url: expenseApi.sessionList2,
    method: "get",
    params: parameter
  });
}
// 拉黑
export function isBlock(parameter) {
  return request({
    url: expenseApi.block,
    method: "post",
    data: parameter
  });
}
// kv
export function kv(parameter) {
  return request({
    url: expenseApi.kv,
    method: "post",
    data: parameter
  });
}
