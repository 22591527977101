<template>
  <Fragment>
    <!-- Sidebar Start -->
    <aside class="sidebar">
      <!-- Tab Content Start -->
      <div class="tab-content">
        <!-- Chat Tab Content Start -->
        <div class="tab-pane active">
          <SiderBarChat v-if="$route.meta.menu == 'chat'" :key="chatKey" />
          <SiderBarSeats v-if="$route.meta.menu == 'seats'" :key="seatsKey" />
          <SiderBarCrm v-if="$route.meta.menu == 'crm'" />
          <SiderBarSet v-if="$route.meta.menu == 'settings'" />
        </div>
      </div>
      <!-- Tab Content End -->
    </aside>
    <!-- Sidebar End -->
  </Fragment>
</template>
<script>
// import chatBox from "@/views/chat/detail";
import { Fragment } from "vue-fragment";
import SiderBarChat from "@/views/chat/menu";
import SiderBarSeats from "@/views/seats/menu";
import SiderBarCrm from "@/views/crm/menu";
import SiderBarSet from "@/views/set/menu";
import eventBus from "@/utils/eventBus";

export default {
  components: {
    Fragment,
    SiderBarChat,
    SiderBarSeats,
    SiderBarCrm,
    SiderBarSet
  },
  data() {
    return {
      typeDropdown: false,
      seatsKey: 123,
      chatKey: 666
    };
  },
  created() {
    eventBus.$on("seatEdit", (data) => {
      if (data == "success") {
        this.seatsKey += 1;
      }
    });
    eventBus.$on("vipChange", () => {
      this.chatKey += 1;
    });
  },
  mounted() {
    // console.log(this.$route);
  },
  methods: {
    typeDropdownToggle() {
      this.typeDropdown = !this.typeDropdown;
    },
    onSearch(e) {
      e.preventDefault();
    }
  }
};
</script>
