<template
  ><div>
    <div class="modal modal-lg-fullscreen fade" :class="{ show: modalShow, 'd-none': !modalDis, 'd-block': modalDis }" @click="close">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom">
        <div class="modal-content" @click.stop="void 0">
          <div class="modal-header">
            <h5 class="modal-title">请选择客服</h5>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-0 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <!-- List Group Start -->
                <ul class="list-group list-group-flush">
                  <li v-if="app_list == null" class="list-group-item text-muted text-center">载入中...</li>
                  <li v-else-if="app_list.length == 0" class="list-group-item text-muted text-center">暂无应用</li>
                  <!-- List Group Item Start -->
                  <li v-else v-for="app of app_list" :key="app.app_id" class="list-group-item list-group-item-hover" @click="chooseApp(app)">
                    <div class="media">
                      <div class="avatar mr-2" :class="{ 'avatar-online': app.is_online, 'avatar-offline': !app.is_online }"><img :src="app.avatar" alt=""/></div>

                      <div class="media-body">
                        <h6 class="text-truncate">
                          <span class="text-reset">{{ app.seat_name }}</span>
                        </h6>

                        <p class="text-muted mb-0">状态：{{ app.is_online == 1 ? "在线" : "离线" }}</p>
                      </div>
                    </div>
                  </li>
                  <!-- List Group Item End -->
                </ul>
                <!-- List Group End -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: modalShow, 'd-none': !modalDis }"></div>
  </div>
</template>
<script>
import { getSeatList } from "@/api/seats";
import md5 from "md5";

export default {
  data() {
    this.md5 = md5;
    return {
      modalDis: true,
      modalShow: false,
      app_list: null,
      statusObj: {
        offline: "离线",
        online: "在线"
      }
    };
  },
  filters: {
    splitName: function(val) {
      return val.substr(0, 2);
    }
  },
  mounted() {
    this.modalDis = true;
    setTimeout(() => {
      this.modalShow = true;
    }, 100);

    getSeatList({ app_id: localStorage.getItem("app_id") }).then((res) => {
      this.app_list = res.result;
      console.log(this.app_list);
      console.log(res);
    });
  },
  methods: {
    close: function() {
      this.modalShow = false;
      setTimeout(() => {
        this.$emit("chooseApp", "close");
      }, 300);
    },
    chooseApp(app) {
      setTimeout(() => {
        this.$emit("chooseApp", app.seat_id);
      }, 300);
    }
  }
};
</script>
<style scoped>
.list-group-item-hover:hover {
  background: #272727;
  cursor: pointer;
}
</style>
