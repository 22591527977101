<template>
  <div class="d-flex flex-column justify-content-center text-center h-100 w-100">
    <div class="container">
      <div class="avatar avatar-lg mb-2">
        <img class="avatar-img" :src="store.state.avatar" />
      </div>
      <h5>欢迎， {{ store.state.seat_name }} ！</h5>
      <p class="text-muted">{{ title }}</p>
      <div class="d-flex justify-content-center d-xl-none" @click="closeMain">
        <div class="btn btn-primary btn-icon"><i class="iconfont icon-back"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import eventBus from "@/utils/eventBus";

export default {
  data() {
    this.store = store;
    return {};
  },
  props: {
    title: String
  },
  methods: {
    closeMain() {
      eventBus.$emit("mainSwitch", false);
    }
  }
};
</script>
