import request from "@/utils/request";

const expenseApi = {
  seatList: "/settings/seatList",
  setseat: "/settings/invite",
  remove: "/settings/remove",
};

export function getSeatList(parameter) {
  return request({
    url: expenseApi.seatList,
    method: "get",
    params: parameter
  });
}

export function setSeat(parameter) {
  return request({
    url: expenseApi.setseat,
    method: "put",
    data: parameter
  });
}

export function remove(parameter) {
  return request({
    url: expenseApi.remove,
    method: "delete",
    params: parameter
  });
}
