// import store from "@/store";

export default [
  {
    path: "/",
    name: "chatList",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "chat"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/chat/:id",
    name: "chatDetail",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "chat"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/chat/:id/details",
    name: "chatDetail",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "chat"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/seats",
    name: "seatsList",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "seats"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/seats/:id([0-9]+)",
    name: "seatsDetail",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "seats"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/seats/add",
    name: "seatsDetail",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "seats"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/crm",
    name: "crmList",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "crm"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/crm/:id([0-9]+)",
    name: "crmList",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "crm"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "settings"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/settings/emoji",
    name: "settings",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "settings"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/settings/user",
    name: "settings",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "settings"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/settings/system",
    name: "settings",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "settings"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/settings/quick",
    name: "settings",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "settings"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/settings/problem",
    name: "settings",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "settings"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/settings/application",
    name: "settings",
    meta: {
      authRequired: true,
      inDashboard: true,
      menu: "settings"
    },
    props: () => ({
      //   user: store.state.auth.currentUser || {}
    })
  },
  {
    path: "/login",
    name: "login",
    // component: () => import("@/views/login"),
    meta: {
      authRequired: false,
      inDashboard: false
    }
  }
];
