<template>
  <div class="d-flex flex-column h-100">
    <div class="hide-scrollbar h-100" id="chatContactsList">
      <!-- Chat Header Start -->
      <div class="sidebar-header sticky-top p-2">
        <div class="d-flex justify-content-between align-items-center">
          <!-- Chat Tab Pane Title Start -->
          <h5 class="font-weight-semibold mb-0">客户</h5>
          <!-- Chat Tab Pane Title End -->
        </div>

        <!-- Sidebar Header Start -->
        <div class="sidebar-sub-header">
          <!-- Sidebar Header Dropdown Start -->
          <div class="dropdown mr-2" :class="{ show: typeDropdown }">
            <!-- Dropdown Button Start -->
            <button class="btn btn-outline-default dropdown-toggle" @click.stop="typeDropdownToggle">
              所有
            </button>
            <!-- Dropdown Button End -->

            <!-- Dropdown Menu Start -->
            <div class="dropdown-menu" :class="{ show: typeDropdown }">
              <a class="dropdown-item">星标</a>
              <a class="dropdown-item">A</a>
              <a class="dropdown-item">B</a>
              <a class="dropdown-item">C</a>
              <a class="dropdown-item">D</a>
            </div>
            <!-- Dropdown Menu End -->
          </div>
          <!-- Sidebar Header Dropdown End -->

          <!-- Sidebar Search Start -->
          <form class="form-inline" @submit="onSearch">
            <div class="input-group">
              <input type="text" class="form-control search border-right-0 transparent-bg pr-0" placeholder="搜索相关客户" />
              <div class="input-group-append">
                <div class="input-group-text transparent-bg border-left-0" role="button">
                  <!-- Default :: Inline SVG -->
                  <svg class="text-muted hw-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>

                  <!-- Alternate :: External File link -->
                  <!-- <img class="injectable hw-20" src="@/assets/media/heroicons/outline/search.svg" alt=""> -->
                </div>
              </div>
            </div>
          </form>
          <!-- Sidebar Search End -->
        </div>
        <!-- Sidebar Header End -->
      </div>
      <!-- Chat Header End -->
      <ul v-if="loading" class="contacts-list">
        <li v-for="i in 6" :key="i" class="contacts-item friends">
          <a class="contacts-link">
            <div class="avatar">
              <span class="skeleton-animate"></span>
            </div>
            <div class="contacts-content">
              <div class="contacts-info">
                <h6 class="chat-name text-truncate"><span class="skeleton-animate" style="display: block; height: .875rem; width: 5rem;"></span></h6>
                <div class="chat-time"></div>
              </div>
              <div class="contacts-texts">
                <p class="text-truncate"><span class="skeleton-animate" style="display: block; height: .875rem; width: 10rem;"></span></p>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <!-- Chat Contact List Start -->

      <ul v-else-if="$store.state.priv.crm && $store.state.priv.crm.read == 1" class="contacts-list" id="chatContactTab" data-chat-list="" v-for="(item, i, index) in crmData" :key="index">
        <!-- Chat Item Start -->
        <p class="sticky-top myTop">{{ i == "start" ? "星标" : i == "other" ? "其他" : i.toUpperCase() }}</p>
        <div class="list-contant">
          <li class="contacts-item friends" v-for="(data, index2) in item" :key="index2" @click="crmDetail(data)" :class="{ active: crm_id == data.client_id }">
            <!-- :class="{ active: seat_id == data.seat_id }" -->
            <router-link class="contacts-link" :to="{ path: `/crm/${data.client_id}` }">
              <div class="avatar">
                <img :src="data.avatar" draggable="false" />
              </div>
              <div class="contacts-content">
                <div class="contacts-info">
                  <h6 class="chat-name text-truncate">{{ data.client_name }}</h6>
                </div>
              </div>
            </router-link>
          </li>
        </div>
        <!-- Chat Item End -->
      </ul>
      <!-- Chat Contact List End -->
    </div>
  </div>
</template>
<script>
import { clientList } from "@/api/crm";
import eventBus from "@/utils/eventBus";

export default {
  data() {
    return {
      loading: false,
      typeDropdown: false,
      crmData: {
        start: [],
        a: [],
        b: [],
        c: [],
        d: [],
        e: [],
        f: [],
        g: [],
        h: [],
        i: [],
        j: [],
        k: [],
        l: [],
        m: [],
        n: [],
        o: [],
        p: [],
        q: [],
        r: [],
        s: [],
        t: [],
        u: [],
        v: [],
        w: [],
        x: [],
        y: [],
        z: [],
        other: []
      },
      perpage: 1000,
      page: 1,
      clientLists: [],
      crm_id: 0
    };
  },
  created() {
    this.getList().then((res) => {
      let id = this.$route.params.id;
      if (res == 1 && id != undefined) {
        this.crm_id = id;
        for (let key in this.crmData) {
          this.crmData[key].forEach((element) => {
            console.log(element, id);
            if (element.client_id == id) {
              eventBus.$emit("clickCrm", element);
              this.$store.commit("clickCrm", element);
            }
          });
        }
      }
    });
    // getClientInfo({app_id:loca}).then((res)=>{
    //   console.log(res)
    // })
  },
  methods: {
    getList() {
      return new Promise((resolve) => {
        this.loading = true;
        clientList({ perpage: this.perpage, page: this.page, app_id: localStorage.getItem("app_id") }).then((res) => {
          console.log(res);
          res.result.forEach((v) => {
            if (v.is_vip == 1) {
              this.crmData.start.push(v);
            } else {
              let flag = 0;
              for (let key in this.crmData) {
                if (key == v.pinyin_prefix) {
                  flag = 1;
                  this.crmData[key].push(v);
                }
              }
              if (flag == 0) {
                this.crmData.other.push(v);
              }
            }
          });
          for (let key2 in this.crmData) {
            if (this.crmData[key2].length == 0) {
              delete this.crmData[key2];
            }
          }
          this.loading = false;
          resolve(1);
          console.log(res);
        });
      });
    },
    typeDropdownToggle() {
      this.typeDropdown = !this.typeDropdown;
    },
    onSearch(e) {
      e.preventDefault();
    },
    crmDetail(data) {
      this.crm_id = data.client_id;
      eventBus.$emit("clickCrm", data);
      this.$store.commit("clickCrm", data);
    }
  }
};
</script>

<style lang="less" scoped>
.contacts-list {
  padding: 0px !important;
}
.list-contant {
  padding: 0.75rem;
}
.myTop {
  line-height: 3rem;
  padding-left: 1rem;
  margin: 0;
  color: white;
  top: 96px !important;
  background: #383f44;
  z-index: 100 !important;
}
</style>
