import Vue from "vue";
import Vuex from "vuex";
// import md5 from "md5";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    seat_id: null,
    seat_name: null,
    showKeyTip: true,
    priv: {}
  },
  mutations: {
    setUserInfo(state, seatInfo) {
      state.seat_id = seatInfo.seat_id;
      state.seat_name = seatInfo.seat_name;
      state.seat_status = seatInfo.status;
      state.priv = seatInfo.priv == undefined ? {} : seatInfo.priv;
      // state.avatar = `https://cdn.v2ex.com/gravatar/${md5(seatInfo.email)}`;
      state.avatar = seatInfo.avatar;
      state.current_session_id = null;
    },
    startChat(state, session) {
      state.current_session_id = session.session_id;
    },
    clickSeat(state, seat) {
      state.seat_id = seat.seat_id;
    },
    clickSet(state, seat) {
      state.set_id = seat.path;
    },
    clickCrm(state, crm) {
      state.crm_id = crm.client_id;
    }
  },
  actions: {},
  modules: {}
});
