<template
  ><div>
    <div class="modal modal-lg-fullscreen fade" :class="{ show: modalShow, 'd-none': !modalDis, 'd-block': modalDis }" @click="close">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom">
        <div class="modal-content" @click.stop="void 0">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-0 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div class="toast-body">
                  <div class="form-group form-row">
                    <div class="col">
                      <div class="form-group form-row">
                        <label class="col-sm-2 col-form-label text-center">问题</label>
                        <div class="col">
                          <input type="text" class="form-control  text-white" id="inlineFormInput" placeholder="问题" v-model="problemObj.question" />
                        </div>
                      </div>
                      <div class="form-group form-row">
                        <label class="col-sm-2 col-form-label text-center">答案</label>
                        <div class="col">
                          <input type="text" class="form-control text-white" id="inlineFormInputGroup" placeholder="答案" v-model="problemObj.answer" />
                        </div>
                      </div>
                      <div class="form-group form-row">
                        <label class="col-sm-2 col-form-label text-center">关键词</label>
                        <div class="col">
                          <input type="text" class="form-control text-white" id="inlineFormInputGroup" placeholder="关键词" v-model="problemObj.kw" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="sure">确认</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: modalShow, 'd-none': !modalDis }"></div>
  </div>
</template>
<script>
import { addProblemList, editProblemList } from "@/api/set";
export default {
  data() {
    return {
      modalDis: true,
      modalShow: false
    };
  },
  props: {
    title: String,
    problemObj: Object
  },

  mounted() {
    this.modalDis = true;
    setTimeout(() => {
      this.modalShow = true;
    }, 100);
  },
  methods: {
    sure() {
      if (this.title == "添加常见问题") {
        this.problemObj.app_id = localStorage.getItem("app_id");
        addProblemList(this.problemObj).then((res) => {
          if (res.code == 200) {
            this.modalShow = false;
            setTimeout(() => {
              this.$emit("closeApp", "success");
            }, 300);
          }
        });
      } else {
        editProblemList(this.problemObj).then((res) => {
          if (res.code == 200) {
            this.modalShow = false;
            setTimeout(() => {
              this.$emit("closeApp", "success");
            }, 300);
          }
        });
      }
    },
    close: function() {
      this.modalShow = false;
      setTimeout(() => {
        this.$emit("closeApp");
      }, 300);
    }
  }
};
</script>

<style scoped>
.modal-content {
  min-width: 600px;
}
</style>
