<template>
  <div class="d-flex flex-column h-100">
    <div class="hide-scrollbar h-100" @scroll="scrollToggle">
      <!-- Chat Header Start -->
      <div class="sidebar-header sticky-top p-2">
        <div class="d-flex justify-content-between align-items-center">
          <!-- Chat Tab Pane Title Start -->
          <h5 class="font-weight-semibold mb-0">会话</h5>
          <!-- Chat Tab Pane Title End -->
        </div>

        <!-- Sidebar Header Start -->
        <div class="sidebar-sub-header">
          <!-- Sidebar Header Dropdown Start -->
          <div class="dropdown mr-2" :class="{ show: typeDropdown }">
            <!-- Dropdown Button Start -->
            <button class="btn btn-outline-default dropdown-toggle" @click.stop="typeDropdownToggle">
              {{ selectObj[selectTitle] }}
            </button>
            <!-- Dropdown Button End -->

            <!-- Dropdown Menu Start -->
            <div class="dropdown-menu" :class="{ show: typeDropdown }">
              <a class="dropdown-item" v-for="(data, index) in selectMenu" :key="index" @click="selectChange(data)">{{ selectObj[data] }}</a>
            </div>
            <!-- Dropdown Menu End -->
          </div>
          <!-- Sidebar Header Dropdown End -->

          <!-- Sidebar Search Start -->
          <form class="form-inline" @submit="onSearch">
            <div class="input-group">
              <input type="text" class="form-control search border-right-0 transparent-bg pr-0" placeholder="搜索相关会话" v-model="searchMessage" />
              <div class="input-group-append">
                <div class="input-group-text transparent-bg border-left-0" role="button">
                  <i class="iconfont icon-search text-muted"></i>
                </div>
              </div>
            </div>
          </form>
          <div class="menuList" @click="showHistory">
            <svg t="1618908868777" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2027" width="30" height="30">
              <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#8C8C8C" p-id="2028"></path>
              <path d="M281.472 358.8864a37.8112 37.8112 0 1 0 75.5968 0 37.8112 37.8112 0 0 0-75.5968 0z m439.1424 17.408H421.9392a21.6832 21.6832 0 0 1 0-43.3152h298.752c11.9296 0 21.6576 9.7024 21.6576 21.632a21.7088 21.7088 0 0 1-21.7344 21.6576zM281.472 512a37.8112 37.8112 0 1 0 75.5968 0 37.8112 37.8112 0 0 0-75.5968 0z m439.1424 17.4592H421.9392a21.6832 21.6832 0 0 1 0-43.3152h298.752c11.9296 0 21.6576 9.728 21.6576 21.6576a21.76 21.76 0 0 1-21.7344 21.6576z m-439.1424 135.68a37.8112 37.8112 0 1 0 75.5968 0 37.8112 37.8112 0 0 0-75.5968 0z m439.1424 17.4336H421.9392a21.6832 21.6832 0 0 1 0-43.3152h298.752c11.9296 0 21.6576 9.728 21.6576 21.6576a21.7088 21.7088 0 0 1-21.7344 21.6576z" fill="#cccccc" p-id="2029"></path>
            </svg>
          </div>
          <!-- Sidebar Search End -->
        </div>
        <!-- Sidebar Header End -->
      </div>
      <!-- Chat Header End -->

      <!-- Chat Contact List Start -->
      <ul v-if="loading" class="contacts-list">
        <li v-for="i in 6" :key="i" class="contacts-item friends">
          <a class="contacts-link">
            <div class="avatar">
              <span class="skeleton-animate"></span>
            </div>
            <div class="contacts-content">
              <div class="contacts-info">
                <h6 class="chat-name text-truncate"><span class="skeleton-animate" style="display: block; height: .875rem; width: 5rem;"></span></h6>
                <div class="chat-time"></div>
              </div>
              <div class="contacts-texts">
                <p class="text-truncate"><span class="skeleton-animate" style="display: block; height: .875rem; width: 10rem;"></span></p>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <ul v-else-if="sessions.length > 0" class="contacts-list">
        <!-- Chat Item Start -->
        <li v-for="sess of sessions" :key="sess.session_id" class="contacts-item friends" :class="{ active: current_session_id == sess.session_id }" @click="startChat(sess)">
          <router-link class="contacts-link" :to="{ path: `/chat/${sess.session_id}` }">
            <div class="avatar" :class="{ 'avatar-online': sess.online, 'avatar-offline': !sess.online }">
              <img :src="sess.avatar" alt="" />
            </div>
            <div class="contacts-content">
              <div class="contacts-info">
                <h6 class="chat-name text-truncate">{{ sess.client_name }}</h6>
                <div class="chat-time">{{ sess.last_msg_time | humanTime }}</div>
              </div>
              <div class="contacts-texts">
                <p class="text-truncate" v-if="sess.last_msg_type == 'html'" v-html="sess.last_msg"></p>
                <p class="text-truncate" v-else>{{ sess.last_msg }}</p>
                <div class="d-inline-flex align-items-center ml-1" v-if="sess.solved">
                  <i data-v-7f1bc284="" class="iconfont icon-tick text-success"></i>
                </div>
                <div class="badge badge-rounded badge-info ml-1" v-if="sess.msg_count > 99">99+</div>
                <div class="badge badge-rounded badge-info ml-1" v-else-if="sess.msg_count > 0">{{ sess.msg_count }}</div>
              </div>
            </div>
          </router-link>
        </li>
        <!-- Chat Item End -->
        <li v-if="loadingMore" class="contacts-item friends">
          <a class="contacts-link">
            <div class="avatar">
              <span class="skeleton-animate"></span>
            </div>
            <div class="contacts-content">
              <div class="contacts-info">
                <h6 class="chat-name text-truncate"><span class="skeleton-animate" style="display: block; height: .875rem; width: 5rem;"></span></h6>
                <div class="chat-time"></div>
              </div>
              <div class="contacts-texts">
                <p class="text-truncate"><span class="skeleton-animate" style="display: block; height: .875rem; width: 10rem;"></span></p>
              </div>
            </div>
          </a>
        </li>
        <li v-else class="text-center">
          <span v-if="!noMore" class="text-muted" @click="loadMore" style="cursor:pointer">载入更多</span>
          <span v-if="noMore" class="text-muted">没有更多了</span>
        </li>
      </ul>
      <ul v-else class="contacts-list text-center">
        <span class="text-muted">暂无会话</span>
      </ul>
      <!-- Chat Contact List End -->
      <div class="w-100 h-100 drawer card" ref="drawer">
        <div class="card-header d-flex align-items-center justify-content-between">
          <div class="text-white">会话列表</div>
          <button class="btn btn-secondary btn-icon btn-minimal  text-white  mr-2" @click="showHistory">
            <i class="iconfont icon-close"></i>
          </button>
        </div>
        <div class="card-body  d-flex flex-column">
          <!-- <div class="message">
          <div class="message-wrapper">
            <div class="message-content"><span style="white-space: pre-line; overflow-wrap: break-word;">域名接入cdn后 如何绑定CNAME</span></div>
          </div>
          <div class="message-options">
            <div class="avatar avatar-sm"><img alt="" src="https://cdn.v2ex.com/gravatar/8d7411f3ef401af0104982f1698a241e" /></div>
            <span class="message-sender">高某</span><span class="message-date">01:17 pm</span>
          </div>
        </div> -->
          <div class="d-flex align-items-center justify-content-center" style="flex:1" v-if="TableMessageLoading">
            <div class="loading-wrp">
              <span class="dot dot-spin"><i></i><i></i><i></i><i></i></span>
            </div>
          </div>
          <div class="hide-scrollbar" style="flex:1;" v-else>
            <table class="table table-striped text-center">
              <thead>
                <tr class="text-white">
                  <th scope="col">会话ID</th>
                  <th scope="col">用户名称</th>
                  <th scope="col">最后一条消息时间</th>
                  <th scope="col">最后一条消息</th>
                  <th scope="col">
                    查看聊天记录
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-white" v-for="data in TableMessages" :key="data.session_id">
                  <th scope="row">{{ data.session_id }}</th>
                  <td>{{ data.client_name }}</td>

                  <td>
                    {{ data.last_msg_time | humanTime }}
                  </td>
                  <td v-if="data.last_msg_type == 'html'" v-html="data.last_msg"></td>
                  <td v-else>
                    {{ data.last_msg }}
                  </td>
                  <td>
                    <div data-v-7f1bc284="" class="btn btn-success" @click="showHistory2(data.session_id)"><span data-v-7f1bc284="">聊天历史</span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-100 myFooter d-flex mt-2" ref="myFooter">
            <div class="dropdown" :class="{ show: typeDropdown2 }">
              <button class="btn btn-primary dropdown-toggle mr-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click.stop="typeDropdownToggle2">{{ pageSize }}条</button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" :class="{ show: typeDropdown2 }">
                <a class="dropdown-item myLink" v-for="data in pageNumbers" :key="data" @click="pageSizeToggle(data)">{{ data }}</a>
              </div>
            </div>
            <nav aria-label="Page navigation example">
              <ul class="pagination mb-0">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" v-for="data in pageArr" :key="data" :class="{ active: data == pageNum ? true : false }">
                  <a class="page-link myLink" @click="pageNumToggle(data)">{{ data }}</a>
                </li>
                <li class="page-item">
                  <a class="page-link" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div class="w-100 h-100 drawer2 card" ref="drawer2">
        <div class="card-header d-flex align-items-center justify-content-between">
          <div class="text-white">聊天历史</div>
          <button class="btn btn-secondary btn-icon btn-minimal  text-white  mr-2" @click="closeHistory2">
            <i class="iconfont icon-close"></i>
          </button>
        </div>
        <div class="card-body  d-flex flex-column">
          <div class="d-flex align-items-center justify-content-center" style="flex:1" v-if="TableMessageLoading2">
            <div class="loading-wrp">
              <span class="dot dot-spin"><i></i><i></i><i></i><i></i></span>
            </div>
          </div>
          <div class="hide-scrollbar" style="flex:1;" v-else>
            <div class="message" v-for="msg of TableMessages2" :key="msg.msg_id" :class="{ self: msg.sender.type == 'seat' }">
              <div class="message-wrapper">
                <div class="message-content">
                  <span v-if="msg.msg_type == 'html'" v-html="msg.content"></span>
                  <span v-if="msg.msg_type == 'img'">
                    <!-- <div class="message-img" style="background-image:url('http://127.0.0.1:7001/public/uploads/2021/03/11/1615452078561412.gif')"></div> -->
                    <img :src="msg.content.store_path" alt="" @load="toBottom" />
                  </span>

                  <span v-if="msg.msg_type == 'attachment'" style="cursor: pointer;color:orange" @click="downloadFile(msg.content.store_path, msg.content.file_name)">
                    {{ msg.content.file_name }}
                  </span>
                  <span v-if="msg.msg_type != 'img' && msg.msg_type != 'html' && msg.msg_type != 'attachment'" style="white-space: pre-line;overflow-wrap: break-word;">{{ msg.content }}</span>
                </div>
              </div>
              <div class="message-options">
                <div class="avatar avatar-sm"><img alt="" :src="msg.sender.avatar == undefined ? app_icon : msg.sender.avatar" /></div>
                <span class="message-sender">{{ msg.sender.name }}</span>
                <span class="message-date">{{ msg.in_time | timeFromat }}</span>
              </div>
            </div>
          </div>
          <div class="w-100 myFooter d-flex mt-2" ref="myFooter2">
            <div class="dropdown" :class="{ show: typeDropdown3 }">
              <button class="btn btn-primary dropdown-toggle mr-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click.stop="typeDropdownToggle3">{{ pageSize2 }}条</button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" :class="{ show: typeDropdown3 }">
                <a class="dropdown-item myLink" v-for="data in pageNumbers" :key="data" @click="pageSizeToggle2(data)">{{ data }}</a>
              </div>
            </div>
            <nav aria-label="Page navigation example">
              <ul class="pagination mb-0">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" v-for="data in pageArr2" :key="data" :class="{ active: data == pageNum2 ? true : false }">
                  <a class="page-link myLink" @click="pageNumToggle2(data)">{{ data }}</a>
                </li>
                <li class="page-item">
                  <a class="page-link" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventBus from "@/utils/eventBus";
import md5 from "md5";
import moment from "moment";
import { getSessionList, getSessInfo, getSessionList2, getMsgRecord } from "@/api/chat";

export default {
  filters: {
    humanTime(value) {
      let duration = Date.now() - value * 1000;
      return duration < 86400 * 3 * 1000 ? moment.duration(duration).humanize() : moment(value * 1000).format("YYYY-MM-DD");
    },
    timeFromat(value) {
      return moment(value * 1000).format("hh:mm a");
    },
  },
  data() {
    this.last_id = 0;
    this.moment = moment;
    this.md5 = md5;
    return {
      selectMenu: ["all", "myself", "unread", "unsolved", "solved", "archive"],
      selectTitle: "all",
      selectObj: {
        all: "所有",
        myself: "我的",
        unread: "未读",
        unsolved: "未解决",
        solved: "已解决",
        archive: "存档",
      },
      typeDropdown: false,
      typeDropdown2: false,
      typeDropdown3: false,
      current_session_id: 0,
      sessions: [],
      loading: true,
      loadingMore: false,
      noMore: false,
      searchMessage: "",
      TableMessageLoading: false,
      TableMessageLoading2: false,
      pageNumbers: [15, 30, 50, 100],
      pageSize: 15,
      pageSize2: 15,
      TableMessages: [],
      TableMessages2: [],
      pageArr: 0,
      pageArr2: 0,
      pageNum: 1,
      pageNum2: 1,
      count: 0,
      count2: 0,
      historyId: 0,
    };
  },
  created() {
    if (localStorage.getItem("app_id") != undefined) {
      let arr = window.location.pathname.split("/");
      this.loadSessionList().then((res) => {
        let id = this.$route.params.id;
        if (res == 1 && id != undefined && arr[arr.length - 1] == "details") {
          let flag = 1;
          for (let item of this.sessions) {
            if (item.session_id == id) {
              this.current_session_id = id;
              flag = 0;
              eventBus.$emit("startChat", item);
              this.$store.commit("startChat", item);
            }
          }
          if (flag == 1) {
            getSessInfo({ session_id: id }).then((res) => {
              if (res.code != 200) {
                return;
              }
              let element = res.result.clientInfos;

              element.last_msg = res.result.sessionList.last_msg;
              element.last_msg_time = res.result.sessionList.last_msg_time;
              element.last_msg_type = res.result.sessionList.last_msg_type;
              element.msg_count = res.result.sessionList.msg_count;
              element.position = res.result.sessionList.loc;
              element.session_id = res.result.sessionList.session_id;
              element.solved = res.result.sessionList.solved;

              element.seat_avatar = res.result.seatInfos.avatar;
              element.seat_email = res.result.seatInfos.email;
              element.seat_id = res.result.seatInfos.seat_id;
              element.seat_name = res.result.seatInfos.seat_name;
              // console.log(res.result.clientInfos, "11111111111111");
              // console.log(res.result.seatInfos, "222222222222222");
              // console.log(res.result.sessionList, "3333333333333");
              this.sessions.unshift(element);
              this.current_session_id = id;
              eventBus.$emit("startChat", element);
              this.$store.commit("startChat", element);
            });
          }
        } else if (res == 1 && id != undefined) {
          this.sessions.forEach((element) => {
            if (element.session_id == id) {
              this.current_session_id = id;
              eventBus.$emit("startChat", element);
              this.$store.commit("startChat", element);
            }
          });
        }
      });
    } else {
      this.loading = false;
    }
  },
  mounted() {
    // if (this.$route.name == "chatDetail") {
    //   this.startChat(this.sessions[0]);
    // }
    // console.log(this.sockets);
  },
  watch: {
    searchMessage: function() {
      this.last_id = 0;
      this.loading = true;
      this.sessions = [];
      this.loadSessionList();
    },
  },
  methods: {
    showHistory() {
      if (this.$refs.drawer.style.right == "0vw") {
        this.$refs.drawer.style.right = "100vw";
      } else {
        this.$refs.drawer.style.right = "0vw";
      }
      this.getListMessage();
    },
    showHistory2(id) {
      if (this.$refs.drawer2.style.right == "0vw") {
        this.$refs.drawer2.style.right = "100vw";
      } else {
        this.$refs.drawer2.style.right = "0vw";
      }
      this.historyId = id;
      this.getListMessage2();
    },
    closeHistory2() {
      if (this.$refs.drawer2.style.right == "0vw") {
        this.$refs.drawer2.style.right = "100vw";
      } else {
        this.$refs.drawer2.style.right = "0vw";
      }
    },
    selectChange(item) {
      this.sessions = [];
      this.selectTitle = item;
      this.typeDropdownToggle();
      this.last_id = 0;
      this.loading = true;
      this.loadSessionList();
    },
    scrollToggle(e) {
      e.target.offsetHeight + e.target.scrollTop == e.target.scrollHeight && this.loadMore();
    },
    loadMore() {
      if (this.loadingMore || this.noMore) {
        return;
      }
      this.loadingMore = true;
      this.loadSessionList();
    },
    loadSessionList() {
      return new Promise((resolve) => {
        getSessionList({
          app_id: localStorage.getItem("app_id"),
          last_id: this.searchMessage == "" ? this.last_id : 0,
          search: this.searchMessage,
          type: this.selectTitle,
        })
          .then((res) => {
            if (res.result.sessionList.length < 20) {
              this.noMore = true;
            } else {
              this.noMore = false;
            }
            if (res.result.sessionList.length == 0) {
              this.noMore = true;
              return;
            }
            let sessionList = [];
            let clientInfos = {};
            let seatInfos = {};
            // 组装信息
            for (let client of res.result.clientInfos) {
              clientInfos[`${client.client_id}`] = client;
            }
            for (let seat of res.result.seatInfos) {
              seatInfos[`${seat.seat_id}`] = seat;
            }

            // console.log(seatInfos);
            for (let sess of res.result.sessionList) {
              // console.log(sess);
              // this.last_id = sess.session_id > this.last_id ? sess.session_id : this.last_id;
              this.last_id = sess.session_id;
              if (!clientInfos[`${sess.client_id}`]) {
                // console.log("客户信息丢失");
                continue;
              } else {
                sess.email = clientInfos[`${sess.client_id}`].email;
                sess.client_name = clientInfos[`${sess.client_id}`].client_name;
                sess.online = clientInfos[`${sess.client_id}`].online;
                sess.is_vip = clientInfos[`${sess.client_id}`].is_vip;
                sess.memo = clientInfos[`${sess.client_id}`].memo;
                sess.avatar = clientInfos[`${sess.client_id}`].avatar;
              }
              if (seatInfos[`${sess.seat_id}`]) {
                sess.seat_name = seatInfos[`${sess.seat_id}`].seat_name;
                sess.seat_email = seatInfos[`${sess.seat_id}`].email;
                sess.seat_avatar = seatInfos[`${sess.seat_id}`].avatar;
              }
              // console.log(sess);
              sessionList.push(sess);
            }
            let flag = 0;
            this.sessions.forEach((element) => {
              if (element.session_id == this.$route.params.id) {
                flag = 1;
              }
            });

            if (flag == 1) {
              sessionList.forEach((v, i) => {
                if (v.session_id == this.$route.params.id) {
                  sessionList.splice(i, 1);
                }
              });
            }

            this.sessions = this.sessions.concat(sessionList);
            resolve(1);
          })
          .finally(() => {
            this.loading = false;
            this.loadingMore = false;
          });
      });
    },
    avatar(email) {
      return email ? `https://cdn.v2ex.com/gravatar/${md5(email)}` : "https://cdn.v2ex.com/gravatar/default";
    },
    typeDropdownToggle() {
      this.typeDropdown = !this.typeDropdown;
    },
    typeDropdownToggle2() {
      if (this.$refs.myFooter.style["padding-bottom"] == "10rem") {
        this.$refs.myFooter.style["padding-bottom"] = "0rem";
      } else {
        this.$refs.myFooter.style["padding-bottom"] = "10rem";
      }
      this.typeDropdown2 = !this.typeDropdown2;
    },
    typeDropdownToggle3() {
      if (this.$refs.myFooter2.style["padding-bottom"] == "10rem") {
        this.$refs.myFooter2.style["padding-bottom"] = "0rem";
      } else {
        this.$refs.myFooter2.style["padding-bottom"] = "10rem";
      }
      this.typeDropdown3 = !this.typeDropdown3;
    },
    pageSizeToggle(data) {
      this.pageSize = data;
      this.typeDropdownToggle2();
      this.getListMessage();
    },
    pageSizeToggle2(data) {
      this.pageSize2 = data;
      this.typeDropdownToggle3();
      this.getListMessage2();
    },
    pageNumToggle(data) {
      // console.log(data, this.pageArr);
      this.pageNum = data;
      this.getListMessage();
    },
    pageNumToggle2(data) {
      // console.log(data);
      this.pageNum2 = data;
      this.getListMessage2();
    },
    // 获取数据
    getListMessage() {
      this.TableMessageLoading = true;
      // console.log(this.pageNum, this.pageSize);
      getSessionList2({
        app_id: localStorage.getItem("app_id"),
        perpage: this.pageSize,
        page: this.pageNum,
      }).then((res) => {
        // console.log(res.result);
        this.TableMessages = [];
        this.count = res.result.count;
        this.setPage(this.count);
        this.TableMessageLoading = false;
        let sessionList = [];
        let clientInfos = {};
        let seatInfos = {};

        // 组装信息
        for (let client of res.result.clientInfos) {
          clientInfos[`${client.client_id}`] = client;
        }
        for (let seat of res.result.seatInfos) {
          seatInfos[`${seat.seat_id}`] = seat;
        }

        // console.log(seatInfos);
        for (let sess of res.result.sessionList) {
          // console.log(sess);
          // this.last_id = sess.session_id > this.last_id ? sess.session_id : this.last_id;
          this.last_id = sess.session_id;
          if (!clientInfos[`${sess.client_id}`]) {
            // console.log("客户信息丢失");
            continue;
          } else {
            sess.email = clientInfos[`${sess.client_id}`].email;
            sess.client_name = clientInfos[`${sess.client_id}`].client_name;
            sess.online = clientInfos[`${sess.client_id}`].online;
            sess.is_vip = clientInfos[`${sess.client_id}`].is_vip;
            sess.memo = clientInfos[`${sess.client_id}`].memo;
            sess.avatar = clientInfos[`${sess.client_id}`].avatar;
          }
          if (seatInfos[`${sess.seat_id}`]) {
            sess.seat_name = seatInfos[`${sess.seat_id}`].seat_name;
            sess.seat_email = seatInfos[`${sess.seat_id}`].email;
            sess.seat_avatar = seatInfos[`${sess.seat_id}`].avatar;
          }
          // console.log(sess);
          sessionList.push(sess);
        }
        this.TableMessages = this.TableMessages.concat(sessionList);

        // console.log(res.result, "1111111111111");
        // console.log(this.TableMessages, "2222222222222222");
      });
    },
    getListMessage2() {
      this.TableMessageLoading2 = true;
      getMsgRecord({ perpage: this.pageSize2, page: this.pageNum2, session_id: this.historyId }).then((res) => {
        this.TableMessages2 = [];
        this.count2 = res.result.count;
        this.setPage2(this.count2);
        this.TableMessageLoading2 = false;
        res.result.list.forEach((element) => {
          this.TableMessages2.unshift(element);
        });

        // console.log(res.result);
        // console.log(this.TableMessages2);
      });
    },
    setPage(num) {
      this.pageArr = num / this.pageSize < 0 ? 1 : Math.ceil(num / this.pageSize);
      if (this.pageNum > this.pageArr) {
        this.pageNum = this.pageArr;
        this.getListMessage();
      }
    },
    setPage2(num) {
      this.pageArr2 = num / this.pageSize2 < 0 ? 1 : Math.ceil(num / this.pageSize2);
      if (this.pageNum2 > this.pageArr2) {
        this.pageNum2 = this.pageArr2;
        this.getListMessage2();
      }
    },
    onSearch(e) {
      e.preventDefault();
    },
    startChat(session) {
      session.msg_count = 0;
      // console.log(session);
      this.current_session_id = session.session_id;
      eventBus.$emit("startChat", session);
      this.$store.commit("startChat", session);
      // router.push();
    },
    updateMessage(session_id, content) {
      let session = {};
      this.sessions.forEach((sess, i) => {
        if (sess.session_id == session_id) {
          if (sess.session_id != this.current_session_id) {
            sess.msg_count++;
          }
          sess.last_msg = content;
          sess.solved = 0;
          sess.last_msg_time = moment.now() / 1000;
          session = JSON.parse(JSON.stringify(sess));
          this.sessions.splice(i, 1);
        }
      });
      if (session.session_id == this.$route.params.id) {
        eventBus.$emit("startChat", session);
        this.$store.commit("startChat", session);
      }
      this.sessions.unshift(session);
    },
  },
  sockets: {
    reciveMessage: function(data) {
      if (data.session_id == this.current_session_id) {
        this.$socket.emit("readMessage", [data.msg_id]);
      }
      if (data.sender.name == undefined && this.searchMessage != "myself") {
        this.last_id = 0;
        this.sessions = [];
        this.loadSessionList();
      } else {
        // console.log(data, "312321333333333333");
        this.updateMessage(data.session_id, data.last_msg);
      }
    },
  },
};
</script>
<style scoped>
.icon-search {
  font-size: 14px;
}
.menuList {
  cursor: pointer;
}
.drawer {
  position: fixed;
  top: 0;
  right: 100vw;
  background: #323333;
  z-index: 10000;
  transition: all 1s;
}
.drawer2 {
  position: fixed;
  top: 0;
  right: 100vw;
  background: #323333;
  z-index: 10000;
  transition: all 1s;
}
</style>
