<template>
  <div class="d-flex flex-column h-100">
    <div class="hide-scrollbar h-100" id="callContactsList">
      <!-- Chat Header Start -->
      <div class="sidebar-header sticky-top p-2">
        <div class="d-flex justify-content-between align-items-center">
          <!-- Chat Tab Pane Title Start -->
          <h5 class="font-weight-semibold mb-0">坐席</h5>
          <!-- Chat Tab Pane Title End -->
        </div>

        <!-- Sidebar Header Start -->
        <div class="sidebar-sub-header">
          <!-- Sidebar Header Dropdown Start -->
          <div class="dropdown mr-2"></div>

          <!-- Sidebar Header Dropdown End  添加 -->
          <ul class="nav flex-nowrap">
            <li class="nav-item list-inline-item mr-1" @click="add">
              <router-link class="nav-link text-muted px-1" title="Notifications" role="button" data-toggle="modal" data-target="#notificationModal" :to="{ path: `/seats/add` }">
                <i class="iconfont icon-adduser"></i>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- Sidebar Header End -->
      </div>
      <!-- Chat Header End -->
      <ul v-if="loading" class="contacts-list">
        <li v-for="i in 6" :key="i" class="contacts-item friends">
          <a class="contacts-link">
            <div class="avatar">
              <span class="skeleton-animate"></span>
            </div>
            <div class="contacts-content">
              <div class="contacts-info">
                <h6 class="chat-name text-truncate"><span class="skeleton-animate" style="display: block; height: .875rem; width: 5rem;"></span></h6>
                <div class="chat-time"></div>
              </div>
              <div class="contacts-texts">
                <p class="text-truncate"><span class="skeleton-animate" style="display: block; height: .875rem; width: 10rem;"></span></p>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <!-- Call Contact List Start 人 -->
      <ul v-else class="contacts-list" id="callLogTab" data-call-list="">
        <!-- Call Item Start -->
        <li class="contacts-item incoming" v-for="data in seatList" :key="data.seat_id" :class="{ active: seat_id == data.seat_id }" @click="seatDetail(data)">
          <router-link :to="{ path: `/seats/${data.seat_id}` }">
            <div class="contacts-link">
              <div class="avatar" :class="{ 'avatar-online': data.is_online, 'avatar-offline': !data.is_online }">
                <img :src="data.avatar" />
              </div>
              <div class="contacts-content">
                <div class="contacts-info">
                  <h6 class="chat-name text-truncate">{{ data.seat_name }}</h6>
                </div>
                <div class="contacts-texts">
                  <!-- Alternate :: External File link -->
                  <!-- <img class="injectable hw-16 text-muted mr-1" src="@/assets/media/heroicons/solid/phone-incoming.svg" alt=""> -->
                  <p class="text-muted mb-0">
                    {{ data.is_online == 1 ? "在线" : "离线" }}
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </li>
        <!-- Call Item End -->
      </ul>
      <!-- Call Contact List Start -->
    </div>
  </div>
</template>
<script>
import { getSeatList } from "@/api/seats";
import eventBus from "@/utils/eventBus";

export default {
  created() {
    this.getList();
  },
  data() {
    return {
      seatList: [],
      seat_id: 0,
      typeDropdown: false,
      lineObj: {
        offline: "已离线",
        online: "在线"
      },
      loading: false
    };
  },
  methods: {
    getList() {
      this.loading = true;
      getSeatList({ app_id: localStorage.getItem("app_id") }).then((res) => {
        this.seatList = res.result;
        if (this.$route.params.id != undefined) {
          this.seat_id = this.$route.params.id;
          res.result.forEach((v) => {
            if (v.seat_id == this.$route.params.id) {
              eventBus.$emit("clickSeat", v);
              this.$store.commit("clickSeat", v);
            }
          });
        } else {
          eventBus.$emit("clickSeat", "del");
          this.$store.commit("clickSeat", "del");
        }
        this.loading = false;
      });
    },
    typeDropdownToggle() {
      this.typeDropdown = !this.typeDropdown;
    },
    onSearch(e) {
      e.preventDefault();
    },
    seatDetail(item) {
      this.seat_id = item.seat_id;
      eventBus.$emit("clickSeat", item);
      this.$store.commit("clickSeat", item);
    },
    add() {
      this.seat_id = null;
      eventBus.$emit("clickSeat", "add");
      this.$store.commit("clickSeat", "add");
    }
  }
};
</script>
<style scoped>
.icon-adduser {
  font-size: 18px;
}
</style>
