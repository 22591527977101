<template
  ><div>
    <div class="modal modal-lg-fullscreen fade " :class="{ show: modalShow, 'd-none': !modalDis, 'd-block': modalDis }" @click="close">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom">
        <div class="modal-content my-modal" @click.stop="void 0">
          <div class="modal-header">
            <h5 class="modal-title">代码展示</h5>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="text-white pl-3" style="margin-bottom:-20px">客服页面地址</div>
              <div class="col-12 pt-2">
                <ssh-pre language="html-vue" dark copy-button class="pageUrl">
                  <template v-slot:copy-button>
                    <i class="iconfont icon-close">复制</i>
                  </template>
                  {{ url }}
                </ssh-pre>
              </div>
            </div>
            <div class="row mt-2">
              <div class="text-white pl-3" style="margin-bottom:-20px">复制粘贴以下代码即可集成随客云客服</div>
              <div class="col-12">
                <ssh-pre language="js" label="Javascript" dark copy-button>
                  <template v-slot:copy-button>
                    <i class="iconfont icon-close">复制</i>
                  </template>
                  {{ html }}
                </ssh-pre>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success btn-sm ml-2" @click="copy">复制</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: modalShow, 'd-none': !modalDis }"></div>
  </div>
</template>
<script>
import md5 from "md5";
import SshPre from "simple-syntax-highlighter";
import "simple-syntax-highlighter/dist/sshpre.css";

export default {
  components: { SshPre },
  data() {
    this.md5 = md5;
    return {
      modalDis: true,
      modalShow: false
    };
  },
  props: {
    html: String,
    url: String
  },
  filters: {
    splitName: function(val) {
      return val.substr(0, 2);
    }
  },
  mounted() {
    this.modalDis = true;
    setTimeout(() => {
      this.modalShow = true;
    }, 100);
  },
  methods: {
    copy() {
      this.documentCopy(this.$refs.SKFHTML).then((res) => {
        if (res == 1) {
          this.$toast.success("复制成功", {
            timeout: 4000,
            position: "top-right"
          });
        }
      });
    },
    documentCopy(ele) {
      if (!(ele !== undefined && ele !== null)) return Promise.resolve(0);
      // 如果支持 select 就使用
      if (ele.select) {
        ele.select();
      } else {
        let selection = window.getSelection();
        let createRange = document.createRange();
        createRange.selectNodeContents(ele);
        selection.removeAllRanges();
        selection.addRange(createRange);
      }
      return new Promise((resolve) => {
        try {
          if (document.execCommand("copy", false, null)) {
            document.execCommand("Copy");
            resolve(1);
          } else {
            resolve(0);
          }
        } catch (err) {
          resolve(0);
        }
      });
    },
    close: function() {
      this.modalShow = false;
      setTimeout(() => {
        this.$emit("chooseApp");
      }, 300);
    }
  }
};
</script>
<style scoped>
.my-modal {
  min-width: 600px;
}
.list-group-item-hover:hover {
  background: #272727;
  cursor: pointer;
}
</style>
<style>
.ssh-pre__copy {
  background-color: rgba(199, 199, 199, 0);
  border-style: none;
  color: #eee;
}
.ssh-pre__content {
  margin-bottom: 0px;
}
.pageUrl span {
  color: white !important;
}
.pageUrl .ssh-pre__content {
  color: white !important;
}
</style>
