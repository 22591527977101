<template>
  <div class="card flex-fill">
    <div class="card-header d-flex align-items-center">
      <button class="btn btn-secondary btn-icon btn-minimal btn-sm text-white d-xl-none mr-2" @click="closeMain">
        <i class="iconfont icon-back"></i>
      </button>
      <div class="text-white">个人设置</div>
    </div>
    <div class="card-body d-flex flex-column  align-items-center">
      <div class="input-group w-50 mb-3 d-flex flex-column align-items-center  justify-content-center">
        <img :src="ImgUrl" draggable="false" style="width:5rem;height:5rem;" :key="imgKey" />
        <button type="button" class="btn btn-primary mt-2" @click="upload">修改头像</button>
        <input type="file" ref="AvatarFile" @change="uploadAvatar" style="display:none" />
      </div>
      <div class="input-group w-50 mb-3">
        <div class="input-group-append">
          <div role="button" class="input-group-text transparent-bg border-left-0" style="padding: 0.275rem 0.75rem;">
            <span class="text-white">姓名</span>
          </div>
        </div>
        <input type="text" placeholder="请输入姓名" class="form-control search border-right-0 transparent-bg pr-0 text-white" v-model="userObj.name" />
      </div>

      <div class="input-group w-50 mb-3">
        <div class="input-group-append">
          <div role="button" class="input-group-text transparent-bg border-left-0" style="padding: 0.275rem 0.75rem;">
            <span class="text-white">邮箱</span>
          </div>
        </div>
        <input type="text" placeholder="请输入邮箱" class="form-control search border-right-0 transparent-bg pr-0 text-white" v-model="userObj.email" />
      </div>

      <div class="input-group w-50 mb-3">
        <div class="input-group-append">
          <div role="button" class="input-group-text transparent-bg border-left-0" style="padding: 0.275rem 0.75rem;">
            <span class="text-white">密码</span>
          </div>
        </div>
        <input type="password" placeholder="请输入密码" class="form-control search border-right-0 transparent-bg pr-0 text-white" v-model="userObj.password" />
      </div>

      <div class="input-group w-50 mb-3">
        <div class="input-group-append">
          <div role="button" class="input-group-text transparent-bg border-left-0" style="padding: 0.275rem 0.75rem;">
            <span class="text-white">确认</span>
          </div>
        </div>
        <input type="password" placeholder="请确认密码" class="form-control search border-right-0 transparent-bg pr-0 text-white" v-model="userObj.surePassword" />
      </div>

      <!-- 按钮 -->
      <div class="w-50">
        <button type="button" class="btn btn-primary" @click="upUser">确定</button>
      </div>
      <div class="w-100 d-flex out">
        <button type="button" class="btn btn-warning" @click="out">退出组织</button>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
import { getSeatInfo } from "@/api/basic";
import { setSelf, outOrg } from "@/api/set";
import md5 from "md5";

export default {
  created() {
    this.getUser();
  },
  data() {
    return {
      userObj: {
        name: "",
        email: "",
        password: "",
        avatar: ""
      },
      URL: "https://cdn.v2ex.com/gravatar/",
      avatarURL: process.env.VUE_APP_API_BASE_URL,
      imgKey: 1,
      ImgUrl: "",
      file: ""
    };
  },
  methods: {
    getUser() {
      getSeatInfo({ app_id: localStorage.getItem("app_id") }).then((res) => {
        this.imgKey += 1;
        this.userObj.name = res.result.seat_name;
        this.userObj.email = res.result.email;
        this.ImgUrl = res.result.avatar;
        // if (res.result.avatar == undefined) {
        //   this.userObj.avatar = this.URL + md5(res.result.email);
        // } else {
        //   if (res.result.avatar.indexOf("/") == 0) {
        //     this.userObj.avatar = this.avatarURL + res.result.avatar;
        //   } else {
        //     this.userObj.avatar = res.result.avatar;
        //   }
        // }
        console.log(this.userObj);
        console.log(res);
      });
    },
    uploadAvatar(e) {
      for (var i = 0; i < e.target.files.length; i++) {
        this.file = e.target.files[i]; //取第1个文件
        var url = window.URL.createObjectURL(this.file);
        this.ImgUrl = url;
      }
      this.$refs.AvatarFile.value = "";
    },
    upload() {
      this.$refs.AvatarFile.click();
    },
    closeMain() {
      eventBus.$emit("mainSwitch", false);
    },
    // 修改个人信息
    upUser() {
      let passFlag = 0;
      if (this.userObj.password.length != 0) {
        if (this.userObj.password.length < 6 || this.userObj.password > 18) {
          this.$toast.error("密码长度错误（6-18位）", {
            timeout: 4000,
            position: "top-right"
          });
          return;
        }
        if (this.userObj.password != this.userObj.surePassword) {
          this.$toast.error("两次密码不一致", {
            timeout: 4000,
            position: "top-right"
          });
          return;
        }
        passFlag = 1;
      }

      if (passFlag == 1) {
        this.userObj.password = md5(this.userObj.password + "suikefu");
      }
      var form = JSON.parse(JSON.stringify(this.userObj));
      var formData = new FormData();
      if (this.file != "") {
        formData.append("file", this.file);
      }
      for (let key in form) {
        formData.append(key, form[key]);
      }

      setSelf(formData).then((res) => {
        this.userObj.password = "";
        this.userObj.surePassword = "";
        if (res.code == 200) {
          this.$toast.success(passFlag == 1 ? "信息修改成功 3 秒后将退出登录,请重新登录" : "信息修改成功", {
            timeout: 4000,
            position: "top-right"
          });
          if (passFlag == 1) {
            setTimeout(() => {
              localStorage.clear();
              window.location.reload();
            }, 3e3);
          } else {
            window.location.reload();
          }
        }
      });
    },
    // 退出组织
    out() {
      outOrg({ app_id: localStorage.getItem("app_id") }).then((res) => {
        if (res.code == 200) {
          this.$toast.success("退出成功", {
            timeout: 4000,
            position: "top-right"
          });
        }
      });
    }
  }
};
</script>
