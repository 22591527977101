<template>
  <!-- Main Start -->
  <main class="main" :class="{ 'main-visible': mainSwitch }">
    <MainChat v-if="$route.meta.menu == 'chat'" />
    <MainSeats v-if="$route.meta.menu == 'seats'" />
    <MainCRM v-if="$route.meta.menu == 'crm'" />
    <MainSet v-if="$route.meta.menu == 'settings'" />
  </main>
  <!-- Main End -->
</template>
<script>
import MainChat from "@/views/chat/detail";
import MainSeats from "@/views/seats/detail";
import MainCRM from "@/views/crm/detail";
import MainSet from "@/views/set/detail";
import eventBus from "@/utils/eventBus";

export default {
  components: { MainChat, MainSeats, MainCRM, MainSet },
  data() {
    return {
      mainSwitch: false
    };
  },
  created() {
    eventBus.$on("mainSwitch", (mainSwitch) => {
      this.mainSwitch = mainSwitch;
    });
  },
  methods: {
    typeDropdownToggle() {
      this.typeDropdown = !this.typeDropdown;
    },
    onSearch(e) {
      e.preventDefault();
    },
    sendMsg(e) {
      e.preventDefault();
      console.log(e);
    }
  }
};
</script>
