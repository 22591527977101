<template>
  <welcome v-if="seatCheck == false" :title="'请选择左侧客服开始操作'" />
  <div class="seats" v-else>
    <div class="d-flex flex-column h-100 w-100 align-items-start card card-body">
      <div class=" w-100 d-flex align-items-center">
        <button class="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none ml-2" @click="closeMain">
          <i class="iconfont icon-back"></i>
        </button>
        <div class="media chat-name align-items-center text-truncate ml-2">
          <div class="avatar mr-3">
            <img :src="settingObj.add == undefined ? settingObj.avatar : app_icon" />
          </div>
          <div class="media-body align-self-center">
            <h6 class="text-truncate mb-0">{{ settingObj.add == undefined ? settingObj.userName : "添加坐席" }}</h6>
            <small class="text-muted">{{ settingObj.add == undefined ? (settingObj.is_online == 1 ? "在线" : "离线") : "" }}</small>
          </div>
        </div>
      </div>

      <div data-v-abbe13e8="" class="input-group ">
        <div data-v-abbe13e8="" class="input-group-append">
          <div data-v-abbe13e8="" role="button" class="input-group-text transparent-bg border-left-0" style="width:6rem;height:2.18rem">
            <span class="text-white">名称</span>
          </div>
        </div>
        <input data-v-abbe13e8="" type="text" placeholder="请输入坐席名" class="form-control search border-right-0 transparent-bg pr-0" v-model="settingObj.userName" />
      </div>
      <div data-v-abbe13e8="" class="input-group ">
        <div data-v-abbe13e8="" class="input-group-append">
          <div data-v-abbe13e8="" role="button" class="input-group-text transparent-bg border-left-0" style="width:6rem;height:2.18rem">
            <span class="text-white">邮箱</span>
          </div>
        </div>
        <input data-v-abbe13e8="" type="text" placeholder="请输入邮箱" class="form-control search border-right-0 transparent-bg pr-0" v-model="settingObj.email" />
      </div>
      <div data-v-abbe13e8="" class="input-group ">
        <div data-v-abbe13e8="" class="input-group-append">
          <div data-v-abbe13e8="" role="button" class="input-group-text transparent-bg border-left-0" style="width:6rem;height:2.18rem">
            <span class="text-white">密码</span>
          </div>
        </div>
        <input data-v-abbe13e8="" type="password" placeholder="请输入密码" class="form-control search border-right-0 transparent-bg pr-0" v-model="settingObj.password" />
      </div>
      <div data-v-abbe13e8="" class="input-group ">
        <div data-v-abbe13e8="" class="input-group-append">
          <div data-v-abbe13e8="" role="button" class="input-group-text transparent-bg border-left-0" style="width:6rem;height:2.18rem">
            <span class="text-white">确认密码</span>
          </div>
        </div>
        <input data-v-abbe13e8="" type="password" placeholder="请确认密码" class="form-control search border-right-0 transparent-bg pr-0" v-model="settingObj.confirm_password" />
      </div>
      <div class="w-100 table-responsive" style="padding: 0px 20px">
        <table class="table table-hover">
          <thead>
            <tr class="text-white">
              <th>权限管理</th>
              <th>
                <label class="my-label"><input type="checkbox" class="mr-2 " @click="selectAll('', 'add')" :disabled="settingObj.seat_id == 1" />添加</label>
              </th>
              <th>
                <label class="my-label"><input type="checkbox" class="mr-2 " @click="selectAll('', 'del')" :disabled="settingObj.seat_id == 1" />删除</label>
              </th>
              <th>
                <label class="my-label"><input type="checkbox" class="mr-2 " @click="selectAll('', 'edit')" :disabled="settingObj.seat_id == 1" />修改</label>
              </th>
              <th>
                <label class="my-label"><input type="checkbox" class="mr-2 " @click="selectAll('', 'read')" :disabled="settingObj.seat_id == 1" />查看</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in seatData" :key="index" class="text-white">
              <td>
                <label class="my-label"><input type="checkbox" class="mr-2 " @click="selectAll(index, 'all')" :disabled="settingObj.seat_id == 1" />{{ privMap[index] ? privMap[index] : index }}</label>
              </td>
              <td class="checkbox">
                <input type="checkbox" v-model="data.add" :disabled="settingObj.seat_id == 1" />
              </td>
              <td class="checkbox">
                <input type="checkbox" v-model="data.del" :disabled="settingObj.seat_id == 1" />
              </td>
              <td class="checkbox">
                <input type="checkbox" v-model="data.edit" :disabled="settingObj.seat_id == 1" />
              </td>
              <td class="checkbox">
                <input type="checkbox" v-model="data.read" :disabled="settingObj.seat_id == 1" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <button type="button" class="btn btn-primary" style="margin: 0px 0px 0px 20px" @click="sub">
          提交
        </button>
        <button type="button" class="btn btn-danger ml-2" @click="del" v-if="settingObj.add == undefined">
          删除客服
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import eventBus from "@/utils/eventBus";
import md5 from "md5";
import { setSeat, remove } from "@/api/seats";
// import moment from "moment";
// import store from "@/store";
import welcome from "@/components/welcome";
const privMap = {
  seat: "坐席",
  crm: "客户",
  emoji: "表情包",
  app: "应用管理",
  set: "系统设置",
  fastReply: "快捷回复",
  problem: "常见问题",
};

const actMap = ["add", "edit", "read", "del"];
export default {
  data() {
    this.privMap = privMap;
    this.actMap = actMap;
    this.app_icon = localStorage.getItem("app_icon");

    return {
      seatCheck: false,
      mainSwitch: false,
      setaDetail: {},
      seatData: {},

      settingObj: {
        userName: "",
        email: "",
        password: "",
        confirm_password: "",
      },
      lineObj: {
        offline: "已离线",
        online: "在线",
      },
    };
  },
  mounted() {
    eventBus.$on("clickSeat", (seat) => {
      eventBus.$emit("mainSwitch", true);
      if (seat == "del") {
        this.seatCheck = false;
        return;
      }
      console.log(seat);
      this.seatCheck = true;
      this.seatData = Object.assign({}, seat.priv);
      if (seat == "add") {
        this.settingObj = {
          userName: "",
          email: "",
          password: "",
          add: true,
        };
        let privs = {};
        for (const priv in this.privMap) {
          privs[priv] = { add: 0, edit: 0, read: 0, del: 0 };
        }
        this.seatData = privs;
        return;
      }
      this.settingObj.userName = seat.seat_name;
      this.settingObj.add = undefined;
      this.settingObj.avatar = seat.avatar;
      this.settingObj.email = seat.email;
      this.settingObj.seat_id = seat.seat_id;
      this.settingObj.status = seat.status;
      this.settingObj.password = "";
      this.settingObj.confirm_password = "";
      this.settingObj.is_online = seat.is_online;
      // seat.priv.forEach((v) => {
      //   this.seatData.forEach((k, j) => {
      //     if (k.priv == v.priv) {
      //       Object.assign(this.seatData[j], v);
      //     }
      //   });
      // });
    });
  },
  components: {
    welcome,
  },
  methods: {
    selectAll(name, method) {
      // console.log(method);
      if (name == "") {
        // 全选方法
        for (let idx in this.seatData) {
          this.seatData[idx][method] = !this.seatData[idx][method];
        }
      } else {
        // 全选模块
        for (let idx in this.seatData) {
          if (idx == name) {
            for (let type of ["add", "edit", "del", "read"]) {
              this.seatData[idx][type] = !this.seatData[idx][type];
            }
          }
        }
      }
    },
    del() {
      remove({ seat_id: this.settingObj.seat_id, app_id: localStorage.getItem("app_id") }).then((res) => {
        if (res.code == 200) {
          this.$toast.success("删除成功", {
            timeout: 4000,
            position: "top-right",
          });
          eventBus.$emit("seatEdit", "success");
          this.$router.push({ path: "/seats" });
        }
      });
    },
    sub() {
      console.log(this.settingObj.confirm_password, this.settingObj.password);
      if (this.settingObj.add != undefined || this.settingObj.password != "") {
        if (this.settingObj.confirm_password != this.settingObj.password) {
          this.$toast.error("两次密码不一致,请重新输入", {
            timeout: 4000,
            position: "top-right",
          });
          return;
        }
        if (this.settingObj.password.length < 8 || this.settingObj.password.length > 16) {
          this.$toast.error("请输入长度为 8 - 16 位的密码", {
            timeout: 4000,
            position: "top-right",
          });
          return;
        }
      }

      let obj = {};
      obj.name = this.settingObj.userName;
      obj.email = this.settingObj.email;
      if (this.settingObj.password != "") {
        obj.password = md5(this.settingObj.password + "suikefu");
      }
      obj.app_id = localStorage.getItem("app_id");
      if (this.settingObj.add == undefined) {
        obj.seat_id = this.settingObj.seat_id;
      }

      // this.seatData.forEach((v) => {
      //   for (let key in v) {
      //     if (v[key] == true) {
      //       v[key] = 1;
      //     } else if (key != "priv") {
      //       v[key] = 0;
      //     }
      //   }
      // });
      let title = this.settingObj.add == undefined ? "修改成功" : "添加成功";
      obj.priv = this.seatData;
      setSeat(obj).then((res) => {
        if (res.code != 200) {
          this.$toast.error(res.msg, {
            timeout: 4000,
            position: "top-right",
          });
        }
        if (res.code == 200) {
          this.$toast.success(title, {
            timeout: 4000,
            position: "top-right",
          });
          eventBus.$emit("seatEdit", "success");
          if (title == "添加成功") {
            this.$router.push({ path: "/seats/" + res.result });
          }
        }
      });
    },
    closeMain() {
      eventBus.$emit("mainSwitch", false);
    },
    typeDropdownToggle() {
      this.typeDropdown = !this.typeDropdown;
    },
    onSearch(e) {
      e.preventDefault();
    },
    sendMsg(e) {
      e.preventDefault();
      console.log(e);
    },
  },
};
</script>

<style lang="less">
.seats {
  display: flex;
  height: 100%;
  flex-grow: 1;
  .input-group {
    padding: 20px;
  }
  .input-group-text {
    padding: 0.295rem 0.75rem;
  }
  .table {
    td,
    th {
      border: 0px;
    }
  }
  .checkbox {
    input {
      cursor: pointer;
    }
  }
}
.my-label {
  cursor: pointer;
  user-select: none;
}
</style>
