<template>
  <div class="chats" @keydown="showEmoji">
    <div class="chat-body" v-if="chatReady" @drop="onFileDrop">
      <!-- Chat Header Start-->
      <div class="chat-header">
        <!-- Chat Back Button (Visible only in Small Devices) -->
        <button class="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none" @click="closeMain">
          <i class="iconfont icon-back"></i>
        </button>

        <!-- Chat participant's Name -->
        <div class="media chat-name align-items-center text-truncate">
          <div class="avatar avatar-online d-none d-sm-inline-block mr-3 ml-3">
            <!-- <img :src="`https://cdn.v2ex.com/gravatar/${md5(session.email)}`" alt="" /> -->
            <img :src="session.avatar" alt="" />
          </div>

          <div class="media-body align-self-center ">
            <h6 class="text-truncate mb-0">{{ session.client_name }}</h6>
            <small class="text-muted">{{ session.online | onlineFilter }}</small>
          </div>
        </div>

        <!-- Chat Options -->
        <ul class="nav flex-nowrap">
          <li class="nav-item list-inline-item mr-1" @click="searchToggle">
            <a class="nav-link text-muted px-1">
              <i class="iconfont icon-search text-muted"></i>
            </a>
          </li>
          <li class="nav-item list-inline-item btn-more-hide mr-0" @click="moreInfoToggle">
            <a class="nav-link text-muted px-1">
              <i class="iconfont icon-more-horizen"></i>
            </a>
          </li>
        </ul>
      </div>
      <!-- Chat Header End-->

      <!-- Search Start -->
      <div class="collapse border-bottom px-3" :class="{ show: searchShow }">
        <div class="container-xl py-2 px-0 px-md-3">
          <div class="input-group bg-light ">
            <input type="text" class="form-control form-control-md border-right-0 transparent-bg pr-0" v-model="kw" placeholder="搜索聊天记录" style="user-select: none;" />
            <div class="input-group-append">
              <span class="input-group-text transparent-bg border-left-0">
                <i class="iconfont icon-search text-muted"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Search End -->

      <!-- Chat Content Start-->
      <div class="chat-content p-2 pt-3" ref="messageBody" @scroll="scrollToggle">
        <div v-if="loadingMore" class="text-center"><span class="text-muted">加载中...</span></div>
        <div v-if="messageLoading" class="container">
          <div class="message">
            <div class="message-wrapper">
              <div class="message-content">
                <span class="skeleton-animate" style="display: block; height: 1.125rem; width: 10rem;"></span>
              </div>
            </div>
            <div class="message-options">
              <div class="avatar avatar-sm"><span class="skeleton-animate" style="box-shadow: 0px 0px 0px 0.5rem #323333;"></span></div>
              <span class="message-sender"> Loading ...</span>
            </div>
          </div>
          <div class="message self">
            <div class="message-wrapper">
              <div class="message-content">
                <span class="skeleton-animate-light" style="display: block; height: 1.125rem; width: 10rem;"></span>
              </div>
            </div>
            <div class="message-options">
              <div class="avatar avatar-sm"><span class="skeleton-animate" style="box-shadow: 0px 0px 0px 0.5rem #323333;"></span></div>
              <span class="message-sender"> Loading ...</span>
            </div>
          </div>
        </div>
        <div v-else class="container" :key="messageKey">
          <!-- Received Message Start -->
          <div class="message" v-for="msg of messages" :key="msg.msg_id" :class="{ self: msg.sender.type == 'seat' }">
            <div class="message-wrapper">
              <div class="message-content">
                <span v-if="msg.msg_type == 'html'" v-html="msg.content"></span>
                <span v-if="msg.msg_type == 'img'" @click="showImgMask(msg.content.store_path)">
                  <!-- <div class="message-img" style="background-image:url('http://127.0.0.1:7001/public/uploads/2021/03/11/1615452078561412.gif')"></div> -->
                  <img :src="msg.content.store_path" alt="" @load="toBottom" />
                </span>

                <span v-if="msg.msg_type == 'attachment'" style="cursor: pointer;color:orange" @click="downloadFile(msg.content.store_path, msg.content.file_name)">
                  {{ msg.content.file_name }}
                </span>
                <span v-if="msg.msg_type != 'img' && msg.msg_type != 'html' && msg.msg_type != 'attachment'" style="white-space: pre-line;overflow-wrap: break-word;">{{ msg.content }}</span>
              </div>
            </div>
            <div class="message-options">
              <div class="avatar avatar-sm"><img alt="" :src="msg.sender.avatar == undefined ? app_icon : msg.sender.avatar" /></div>
              <span class="message-sender">{{ msg.sender.name }}</span>
              <span class="message-date">{{ msg.in_time | timeFromat }}</span>
            </div>
          </div>
          <!-- Received Message End -->
        </div>

        <!-- Scroll to finish -->
        <div class="chat-finished" id="chat-finished"></div>
      </div>
      <!-- Chat Content End-->
      <div class="keys d-flex justify-content-center align-items-center" v-if="showKeyTip">
        <div class="keyTip text-white ">
          <div class="key leftctrl">ctrl</div>
          +
          <div class="key">/</div>
        </div>
        即可呼出表情
        <div class="loadline" :style="hideWidth"></div>
      </div>
      <div class="uploadLine" :style="uploadWidth"></div>
      <div class="chat-typing ml-2">
        <p v-for="(typer, idx) of typings" :key="idx" class="text-muted m-1">{{ typer.name }} 正在输入 : “ {{ typer.content }} ”</p>
      </div>

      <div class="chat-Gif" v-if="eojiBool">
        <div class="close-Gif" @click="hideEmoji">
          <i class="iconfont icon-close"></i>
        </div>
        <div class="GIf-nav w-100 d-flex  p-2 aligin-litems-center">
          <div style="width:100px">
            <input type="text" placeholder="关键字" class="form-control search" v-if="GifBool" v-model="Gifkw" />
          </div>

          <div class="d-flex justify-content-center flex-fill pt-1">
            <div class="text-white mr-4" @click="emoji" :class="{ on: GifBool == false }">字符表情</div>
            <div class="text-white" v-if="$store.state.priv.emoji && $store.state.priv.emoji.read == 1" @click="Gif" :class="{ on: GifBool == true }">动图</div>
          </div>
          <div style="width:100px;opacity: 0;">123</div>
        </div>
        <div class="Gif-box" v-if="GifBool == false">
          <div class="emoji-items" v-for="(data, index) in emojis" :key="index" @click="addEmoji">{{ data }}</div>
        </div>
        <div class="Gif-box" ref="GifBox" v-if="GifBool" @scroll="GifMore">
          <div class="Gif-content imgBlock" v-for="data in emojiList" :key="data.emoji_id" @click="sendEmoji(data.emoji_id)">
            <div :style="'background:url(' + data.path + ')'"></div>
          </div>
        </div>
      </div>
      <div class="quick" v-if="$store.state.priv.fastReply && $store.state.priv.fastReply.read == 1">
        <div ref="quickSpeak">
          <div v-for="data in qucikList" :key="data.id" @click="sendQuickMessage(data)">{{ data.fields }}</div>
        </div>
        <div class="text-white" @click="showQuick">快</div>
      </div>
      <!-- Chat Footer Start-->
      <div class="chat-footer">
        <div class="attachment">
          <a class="btn  btn-icon btn-sm" @click="uploadAttchment">
            <i class="iconfont icon-attachment text-muted"></i>
          </a>
        </div>
        <textarea ref="textarea" v-model="textarea" class="form-control emojionearea-form-control hide-scrollbar" rows="1" placeholder="将你的消息发送至" @compositionend="compositionend" @compositionstart="compositionstart" @keydown="keyDownToggle" @paste="onPaste"></textarea>
        <div class="btn btn-primary btn-icon send-icon rounded-circle text-light mb-1" @click="sendTextMsg">
          <i class="iconfont icon-goto"></i>
        </div>
      </div>
      <!-- Chat Footer End-->
    </div>
    <!-- 初始化页面 -->
    <Welcome v-else :title="'请从左侧会话列表选择会话'" />
    <!-- Chat Info Start -->
    <div class="chat-info" v-if="chatReady" :class="{ 'chat-info-visible': moreInfo }">
      <div class="d-flex h-100 flex-column">
        <!-- Chat Info Header Start -->
        <div class="chat-info-header px-2">
          <div class="container-fluid">
            <ul class="nav justify-content-between align-items-center">
              <!-- Sidebar Title Start -->
              <li class="text-center">
                <h5 class="text-truncate mb-0">更多信息</h5>
              </li>
              <!-- Sidebar Title End -->

              <!-- Close Sidebar Start -->
              <li class="nav-item list-inline-item btn-more-hide">
                <a class="nav-link text-muted px-0" @click="moreInfoToggle">
                  <i class="iconfont icon-close"></i>
                  <input type="file" ref="upload" style="display:none" @change="uploadFile" />
                </a>
              </li>

              <!-- Close Sidebar End -->
            </ul>
          </div>
        </div>
        <!-- Chat Info Header End  -->

        <!-- Chat Info Body Start  -->
        <div class="hide-scrollbar flex-fill">
          <!-- User Profile Start -->
          <div class="p-3">
            <div class="d-flex">
              <!-- User Profile Picture -->
              <div class="avatar avatar-lg mb-3">
                <img class="avatar-img" :src="session.avatar" alt="" />
              </div>

              <!-- User Info -->
              <div class="d-flex flex-column ml-3">
                <h5 class="mb-1">{{ session.client_name }}</h5>
                <p class="mb-1 text-muted">{{ session.email | showEmail }}</p>
                <p class="text-muted">
                  <i class="iconfont icon-location mr-1"></i>
                  <span>{{ session.position == "" ? "未获取到地理位置" : session.position }}</span>
                </p>
              </div>
            </div>
            <!-- User Quick Options -->
            <div class="d-flex">
              <div class="btn btn-icon rounded-circle text-light mx-1 my-icon" title="星标该用户" @click="setvip" :class="{ myActive: session.is_vip == 1 ? true : false }">
                <i class="iconfont icon-like"></i>
              </div>
              <div class="btn btn-success btn-icon rounded-circle text-light mx-1" title="标注已完成" @click="myOver" :class="{ myClose: session.solved == 1 ? true : false }">
                <i class="iconfont icon-tick" v-if="session.solved == 0"></i>
                <i class="iconfont icon-close" v-else></i>
              </div>
              <div class="btn btn-success text-light mx-1 d-flex align-items-center" @click="showHistory">
                <span>聊天历史</span>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div class="btn btn-danger text-light mx-1 d-flex align-items-center" @click="block">
                <span ref="blockText">未拉黑</span>
              </div>
            </div>
          </div>
          <!-- User Profile End -->

          <!-- User Information Start -->
          <div class="chat-info-group">
            <a class="chat-info-group-header">
              <h6 class="mb-0">客服信息</h6>
            </a>

            <div class="chat-info-group-body">
              <div class="chat-info-group-content d-flex align-items-center">
                <div v-if="session.seat_id > 0" class="avatar avatar-sm mr-2">
                  <img class="avatar-img" :src="session.seat_avatar" alt="" />
                </div>
                <div v-if="session.seat_id > 0" class="text-muted mr-3">{{ session.seat_name }}</div>
                <div v-else class="text-muted mr-3">未指派</div>
                <div v-if="session.seat_id > 0" class="btn btn-sm btn-danger" @click="setMemo">重新指派</div>
                <div v-else class="btn btn-sm btn-success" @click="setMemo">立即指派</div>
              </div>
            </div>
          </div>
          <!-- User Information End -->

          <div class="chat-info-group" :key="dataKey">
            <a class="chat-info-group-header">
              <h6 class="mb-0">客户信息</h6>
            </a>

            <div class="chat-info-group-body">
              <div class="list-group my-list-group flex-fill">
                <li class="list-group-item text-white" style="padding:0.5rem 1.75rem;" v-for="(data, index) in session.kv_data" :key="index">
                  <div class="form-group d-flex align-items-center my-group mb-0">
                    <label class="flex-fill mb-0">{{ data.key }}:</label><input type="text" v-model="data.value" :placeholder="'请输入' + data.key" class="form-control text-white" />
                  </div>
                </li>
                <li class="list-group-item text-white" style="padding:0.5rem 1.75rem;">
                  <div class="btn btn-block btn-success" style="display:block;" @click="sureKv">确认</div>
                </li>
              </div>
            </div>
          </div>

          <!-- Shared Media Start -->
          <div class="chat-info-group">
            <a class="chat-info-group-header">
              <h6 class="mb-0">备注</h6>
              <h6 class="mb-0" style="cursor: pointer;" @click="reload">刷新</h6>
            </a>

            <div class="chat-info-group-body">
              <div class="chat-info-group-content">
                <form>
                  <div class="form-group">
                    <textarea class="form-control memo-form-control text-muted" rows="19" v-model="memoTextarea"></textarea>
                  </div>
                  <div class="form-group">
                    <div class="btn btn-block btn-success" @click="sureMemo">保存</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Shared Media End -->
        </div>
        <!-- Chat Info Body Start  -->
      </div>
    </div>
    <!-- Chat Info End -->
    <ChooseApp v-if="serviceBool" @chooseApp="chooseApp" />
    <div class="w-100 h-100 drawer card" ref="drawer">
      <div class="card-header d-flex align-items-center justify-content-between">
        <div class="text-white">聊天历史</div>
        <button class="btn btn-secondary btn-icon btn-minimal  text-white  mr-2" @click="showHistory">
          <i class="iconfont icon-close"></i>
        </button>
      </div>
      <div class="card-body  d-flex flex-column">
        <!-- <div class="message">
          <div class="message-wrapper">
            <div class="message-content"><span style="white-space: pre-line; overflow-wrap: break-word;">域名接入cdn后 如何绑定CNAME</span></div>
          </div>
          <div class="message-options">
            <div class="avatar avatar-sm"><img alt="" src="https://cdn.v2ex.com/gravatar/8d7411f3ef401af0104982f1698a241e" /></div>
            <span class="message-sender">高某</span><span class="message-date">01:17 pm</span>
          </div>
        </div> -->
        <div class="d-flex align-items-center justify-content-center" style="flex:1" v-if="TableMessageLoading">
          <div class="loading-wrp">
            <span class="dot dot-spin"><i></i><i></i><i></i><i></i></span>
          </div>
        </div>
        <div class="hide-scrollbar" style="flex:1;" v-else>
          <div class="message" v-for="msg of TableMessages" :key="msg.msg_id" :class="{ self: msg.sender.type == 'seat' }">
            <div class="message-wrapper">
              <div class="message-content">
                <span v-if="msg.msg_type == 'html'" v-html="msg.content"></span>
                <span v-if="msg.msg_type == 'img'" @click="showImgMask(msg.content.store_path)">
                  <!-- <div class="message-img" style="background-image:url('http://127.0.0.1:7001/public/uploads/2021/03/11/1615452078561412.gif')"></div> -->
                  <img :src="msg.content.store_path" alt="" @load="toBottom" />
                </span>

                <span v-if="msg.msg_type == 'attachment'" style="cursor: pointer;color:orange" @click="downloadFile(msg.content.store_path, msg.content.file_name)">
                  {{ msg.content.file_name }}
                </span>
                <span v-if="msg.msg_type != 'img' && msg.msg_type != 'html' && msg.msg_type != 'attachment'" style="white-space: pre-line;overflow-wrap: break-word;">{{ msg.content }}</span>
              </div>
            </div>
            <div class="message-options">
              <div class="avatar avatar-sm"><img alt="" :src="msg.sender.avatar == undefined ? app_icon : msg.sender.avatar" /></div>
              <span class="message-sender">{{ msg.sender.name }}</span>
              <span class="message-date">{{ msg.in_time | timeFromat }}</span>
            </div>
          </div>
        </div>
        <div class="w-100 myFooter d-flex mt-2" ref="myFooter">
          <div class="dropdown" :class="{ show: typeDropdown }">
            <button class="btn btn-primary dropdown-toggle mr-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click.stop="typeDropdownToggle">{{ pageSize }}条</button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" :class="{ show: typeDropdown }">
              <a class="dropdown-item myLink" v-for="data in pageNumbers" :key="data" @click="pageSizeToggle(data)">{{ data }}</a>
            </div>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination mb-0">
              <li class="page-item">
                <a class="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item" v-for="data in pageArr" :key="data" :class="{ active: data == pageNum ? true : false }">
                <a class="page-link myLink" @click="pageNumToggle(data)">{{ data }}</a>
              </li>
              <li class="page-item">
                <a class="page-link" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="w-100 h-100 card ShowImg text-white d-flex" @mousewheel="mouseWhell" v-if="Imgshow">
      <div class="card-header d-flex" style="justify-content: space-between;align-items: center;">
        <div>图片查看</div>
        <div class="d-flex" style="width:8rem;justify-content: space-between;align-items: center;">
          <div class="Imgaction" @click="Imgplus">+</div>
          <div>{{ proportion }}%</div>
          <div class="Imgaction" @click="Imgsmall">-</div>
        </div>
        <div>
          <i data-v-7f1bc284="" class="iconfont icon-close Imgclose" @click="closeImg"></i>
        </div>
      </div>
      <div class="flex-fill">
        <div class="imgBox">
          <img :src="Imgurl" draggable="false" ref="imgBox" @mousedown="mouseDown" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventBus from "@/utils/eventBus";
import md5 from "md5";
import moment from "moment";
import store from "@/store";
import { getMessageList, setVip, over, serMemo, SendFile, getMsgRecord, isBlock, kv } from "@/api/chat";
import { getEmojiList, getQuickList } from "@/api/set";
import ChooseApp from "@/components/modal/chooseService.vue";
import Welcome from "@/components/welcome.vue";
import { getClientInfo } from "@/api/basic";

export default {
  created() {
    console.log(this.$store.state.priv);
    if (localStorage.getItem("app_id") == undefined) {
      this.messageLoading = false;
    }
  },
  components: {
    ChooseApp,
    Welcome,
  },
  computed: {
    showKeyTip: {
      get() {
        return this.$store.state.showKeyTip;
      },
      set(val) {
        this.$store.state.showKeyTip = val;
      },
    },
  },
  filters: {
    showEmail(email) {
      return email ? email : "未设置";
    },
    onlineFilter(online) {
      return online == 1 ? "在线" : "已离线";
    },
    timeFromat(value) {
      return moment(value * 1000).format("hh:mm a");
    },
  },
  data() {
    this.store = store;
    this.last_id = 0;
    this.autoBottom = true;
    this.app_icon = localStorage.getItem("app_icon");
    return {
      isTypewritingBool: false,
      dataKey: 1234,
      md5,
      Imgshow: false,
      kw: "",
      Gifkw: "",
      messageKey: 111132131,
      pageSize: 15,
      pageNum: 1,
      count: 0,
      pageArr: 0,
      typeDropdown: false,
      TableMessageLoading: false,
      pageNumbers: [15, 30, 50, 100],
      qucikList: [],
      GifBool: false,
      messageLoading: true,
      chatReady: false,
      searchShow: false,
      moreInfo: false,
      session: {
        sess_id: 0,
        client_id: 0,
        client_name: "-",
        online: 0,
        solved: 0,
        last_msg: "-",
        msg_count: 0,
        last_time: 0,
        email: "-",
        is_block: 0,
      },
      textarea: "",
      messages: [],
      TableMessages: [],
      typings: {},
      loadingMore: false,
      eojiBool: false,
      emojiList: [],
      URL: process.env.VUE_APP_API_BASE_URL,
      serviceBool: false,
      memoTextarea: "",
      hideWidth: {
        width: "0%",
      },
      uploadRate: 0,
      uploadWidth: {
        width: "0%",
      },
      proportion: 100,
      GifPerpage: 16,
      GifPage: 1,
      GifLoadingMode: true,
      Imgurl: "",
      emojis: ["😀", "😁", "😂", "🤣", "😃", "😄", "😅", "😆", "😉", "😊", "😋", "😎", "😍", "😘", "😗", "🥰", "🤨", "🤔", "🤩", "🤗", "🙂", "😚", "😙", "😐", "😑", "😶", "🙄", "😏", "😥", "😣", "😮", "😛", "😌", "🥱", "😴", "😫", "😪", "😯", "🤐", "😜", "😝", "🤤", "😒", "😓", "😔", "😕", "🙃", "😤", "😟", "😞", "😖", "🙁", "😲", "🤑", "😢", "😭", "😦", "😧", "😨", "😩", "🤯", "😬", "🥴", "😵", "🤪", "😳", "🥶", "🥵", "😱", "😰", "😠", "😡", "🤬", "😷", "🤒", "🤕", "🤢", "🤮", "🤫", "🤥", "🤡", "🤠", "🥺", "🥳", "😇", "🤧", "🤭", "🧐", "🤓", "😈"],
    };
  },
  mounted() {
    var count = 0;
    var int = setInterval(() => {
      if (count > 100) {
        return;
      }
      this.hideWidth = { width: count++ + "%" };
    }, 35);
    setTimeout(() => {
      this.showKeyTip = false;
      clearInterval(int);
    }, 4e3);
    // console.log(this.$refs);
    eventBus.$on("startChat", (session) => {
      console.log(session);
      eventBus.$emit("mainSwitch", true);
      if (session.session_id != this.session.session_id) {
        if (this.session.session_id) {
          this.typing(this.session.session_id, "");
        }
        console.log(session);
        this.messages = [];
        this.textarea = "";
        this.typings = {};
        this.session = session;
        this.chatReady = true;
        this.searchShow = false;
        this.messageLoading = true;
        this.last_id = 0;
        this.autoBottom = true;
        this.memoTextarea = this.session.memo;

        this.kw = "";
        this.loadMessageList();
      } else {
        Object.assign(this.session, session);
        this.memoTextarea = this.session.memo;
      }
      this.reload();
    });
    // const that = this;
    // this.typing_timer = setInterval(() => {
    //   for (const [key, value] of Object.entries(that.typings)) {
    //     if (value.ts < moment.now()) {
    //       delete that.typings[key];
    //     }
    //   }
    //   that.typings = Object.assign({}, that.typings);
    // }, 1e3);
  },
  watch: {
    "$store.state.seat_id": function(newval) {
      console.log(newval);
      this.messageKey += 1;
    },
    kw() {
      this.last_id = 0;
      this.messages = [];
      this.messageLoading = true;
      this.loadMessageList();
    },
    Gifkw() {
      this.emojiList = [];
      this.getGifList();
    },
    textarea(content) {
      // if (!content) return;
      // const now = moment.now();
      // if (this.typing_last + 3000 < now) {
      //   this.typing_last = now;
      this.typing(this.session.session_id, content);
      // }
    },
  },
  destroyed() {
    console.log("chat-detail Destroyed");
    clearInterval(this.typing_timer);
  },
  methods: {
    sureKv() {
      console.log(JSON.stringify(this.session.kv_data));
      kv({ kv_data: JSON.stringify(this.session.kv_data), client_id: this.session.client_id }).then((res) => {
        if (res.code == 200) {
          this.$toast.success("修改成功", {
            timeout: 4000,
            position: "top-right",
          });
          this.reload();
        }
      });
    },
    block() {
      isBlock({ session_id: this.session.session_id, is_block: this.session.is_block == 1 ? 0 : 1 }).then(() => {
        this.$toast.success(this.session.is_block == 1 ? "取消拉黑成功" : "拉黑成功", {
          timeout: 4000,
          position: "top-right",
        });

        this.reload();
      });
    },
    showImgMask(url) {
      this.Imgshow = true;
      this.Imgurl = url;
    },
    mouseDown(ev) {
      var that = this;
      var disX = ev.clientX - this.$refs.imgBox.offsetLeft; //计算X轴，div盒子左边框与鼠标之间的距离
      var disY = ev.clientY - this.$refs.imgBox.offsetTop; //计算Y轴，div盒子上边框与鼠标之间的距离
      console.log(disX);
      console.log(disY);
      document.onmousemove = function(ev) {
        var l = ev.clientX - disX; //计算X轴，浏览器左边窗口与div盒子左边边框的距离
        var t = ev.clientY - disY - 58; //计算Y轴，浏览器上边窗口与div盒子上边边框的距离
        that.$refs.imgBox.style.left = l + "px";
        that.$refs.imgBox.style.top = t + "px";
      };
      document.onmouseup = function() {
        document.onmousemove = null;
      };
      return false;
    },
    Imgplus() {
      this.proportion = this.proportion >= 500 ? 500 : this.proportion + 10;
      this.$refs.imgBox.style.transform = "translate(-50%, -50%) scale(" + this.proportion / 100 + ")";
    },
    Imgsmall() {
      this.proportion = this.proportion <= 20 ? 20 : this.proportion - 10;
      this.$refs.imgBox.style.transform = "translate(-50%, -50%) scale(" + this.proportion / 100 + ")";
    },
    closeImg() {
      this.Imgshow = false;
    },
    mouseWhell(ev) {
      if (ev.wheelDelta < 0) {
        this.proportion = this.proportion <= 20 ? 20 : this.proportion - 10;
      } else {
        this.proportion = this.proportion >= 500 ? 500 : this.proportion + 10;
      }
      this.$refs.imgBox.style.transform = "translate(-50%, -50%) scale(" + this.proportion / 100 + ")";
    },
    GifMore(e) {
      if (e.target.offsetHeight + e.target.scrollTop == e.target.scrollHeight) {
        this.GifPage += 1;
        this.getGifList();
      }
    },
    pageSizeToggle(data) {
      this.pageSize = data;
      this.typeDropdownToggle();
      this.getListMessage();
    },
    pageNumToggle(data) {
      this.pageNum = data;
      this.getListMessage();
    },
    typeDropdownToggle() {
      if (this.$refs.myFooter.style["padding-bottom"] == "10rem") {
        this.$refs.myFooter.style["padding-bottom"] = "0rem";
      } else {
        this.$refs.myFooter.style["padding-bottom"] = "10rem";
      }
      this.typeDropdown = !this.typeDropdown;
    },
    showHistory() {
      if (this.$refs.drawer.style.left == "0vw") {
        this.$refs.drawer.style.left = "100vw";
      } else {
        this.$refs.drawer.style.left = "0vw";
      }
      this.getListMessage();
    },
    getListMessage() {
      this.TableMessageLoading = true;
      getMsgRecord({ perpage: this.pageSize, page: this.pageNum, session_id: this.session.session_id }).then((res) => {
        this.TableMessages = [];
        this.count = res.result.count;
        this.setPage(this.count);
        this.TableMessageLoading = false;
        res.result.list.forEach((element) => {
          // console.log(element);
          // if (element.msg_type == "img" || element.msg_type == "attachment") {
          //   element.content = JSON.parse(element.content);
          //   if (element.content.store_path.indexOf("/") == 0) {
          //     element.content.store_path = `${this.URL}${element.content.store_path}`;
          //   }
          // }
          this.TableMessages.unshift(element);
        });

        console.log(res.result);
        console.log(this.TableMessages);
      });
    },
    setPage(num) {
      this.pageArr = num / this.pageSize < 0 ? 1 : Math.ceil(num / this.pageSize);
      if (this.pageNum > this.pageArr) {
        this.pageNum = this.pageArr;
        this.getListMessage();
      }
    },
    sendQuickMessage(data) {
      this.textarea += data.fields;
      // this.sendMsg("text", data.fields);
      this.showQuick();
    },
    addEmoji(e) {
      this.textarea += e.target.innerHTML;
    },
    emoji() {
      this.GifBool = false;
    },
    Gif() {
      if (this.$store.state.priv.emoji && this.$store.state.priv.emoji.read == 1) {
        this.getGifList();
        console.log(this.$refs.GifBox);
        this.GifBool = true;
      }
    },
    getGifList() {
      getEmojiList({ perpage: this.GifPerpage, page: this.GifPage, app_id: localStorage.getItem("app_id"), kw: this.Gifkw }).then((res) => {
        console.log(res.result);
        if (res.result.length == 0) {
          return;
        }
        if (res.code != 200) {
          return;
        }
        console.log(res);
        this.emojiList = this.emojiList.concat(res.result);
      });
    },
    showQuick() {
      if (this.$store.state.priv.fastReply && this.$store.state.priv.fastReply.read == 1) {
        getQuickList({ app_id: localStorage.getItem("app_id") }).then((res) => {
          console.log(res);
          this.qucikList = res.result;
        });
      }
      if (this.$refs.quickSpeak.style.left == "0vw") {
        this.$refs.quickSpeak.style.left = "100vw";
      } else {
        this.$refs.quickSpeak.style.left = "0vw";
      }
    },
    // 请求个人信息接口
    reload() {
      this.session.kv_data = "";
      getClientInfo({ client_id: this.session.client_id }).then((res) => {
        if (res.result.is_block == 1) {
          this.$refs.blockText.innerHTML = "已拉黑";
        } else {
          this.$refs.blockText.innerHTML = "未拉黑";
        }
        this.session.memo = res.result.memo;
        this.session.position = res.result.loc;
        this.session.is_block = res.result.is_block;
        this.memoTextarea = res.result.memo;
        console.log(res.result, "1111111111111111111");
        if (res.result.kv_data != "") {
          this.session.kv_data = JSON.parse(res.result.kv_data);
        } else {
          this.session.kv_data = res.result.kv_data;
        }
        console.log(this.session.kv_data, "wwwwwwwwwwwwwwwwwww");
        this.dataKey = this.dataKey++;
      });
    },
    downloadFile(url, fileName) {
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function() {
        //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
        var url = window.URL.createObjectURL(x.response);
        var a = document.createElement("a");
        a.href = url;
        a.download = "随客云-" + fileName;
        a.click();
      };
      x.send();
    },
    uploadFile: function(e) {
      var vm = this;
      var formData = new FormData();
      formData.append("session_id", this.session.session_id);
      formData.append("app_id", localStorage.getItem("app_id"));
      var arr = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
      var file = null;
      for (var i = 0; i < e.target.files.length; i++) {
        file = e.target.files[i]; //取第1个文件
        formData.append("file", file);
      }

      SendFile(formData).then((data) => {
        document.getElementsByClassName("uploadLine")[0].style.display = "block";
        vm.uploadWidth.width = "100%";
        setTimeout(() => {
          document.getElementsByClassName("uploadLine")[0].style.display = "none";
          vm.uploadWidth.width = "0%";
        }, 2e3);
        if (arr.indexOf(file.type) != -1) {
          vm.$socket.emit("sendMessage", {
            msg_type: "img",
            content: data.result,
            session_id: this.session.session_id,
          });
        } else {
          vm.$socket.emit("sendMessage", {
            msg_type: "attachment",
            content: data.result,
            session_id: this.session.session_id,
          });
        }
      });
    },
    // 上传附件
    uploadAttchment() {
      this.$refs.upload.click();
    },
    // 选妃弹框
    chooseApp(data) {
      if (data != "close") {
        this.$socket.emit("setSeat", { seat_id: data, session_id: this.session.session_id });
      }
      this.serviceBool = !this.serviceBool;
    },
    // 添加备忘录
    sureMemo() {
      serMemo({ md5Memo: md5(this.session.memo), memo: this.memoTextarea, client_id: this.session.client_id }).then((res) => {
        if (res.code == 200) {
          this.$toast.success("添加备注成功", {
            timeout: 4000,
            position: "top-right",
          });
        }
      });
    },
    // 重新指派
    setMemo() {
      this.serviceBool = true;
    },
    setvip() {
      this.session.is_vip == 0 ? (this.session.is_vip = 1) : (this.session.is_vip = 0);
      setVip({ client_id: this.session.client_id, is_vip: this.session.is_vip }).then((res) => {
        if (res.code == 200) {
          this.$toast.success(this.session.is_vip == 1 ? "添加星标成功" : "取消星标成功", {
            timeout: 4000,
            position: "top-right",
          });
        }
        console.log(res);
      });
    },
    myOver() {
      this.session.solved == 0 ? (this.session.solved = 1) : (this.session.solved = 0);
      over({ session_id: this.session.session_id, solved: this.session.solved }).then((res) => {
        if (res.code == 200) {
          this.$toast.success(this.session.solved == 1 ? "已结束会话" : "已开始会话", {
            timeout: 4000,
            position: "top-right",
          });
        }
      });
    },

    sendEmoji(id) {
      this.$socket.emit("sendMessage", { msg_type: "emoji", content: id, session_id: this.session.session_id });
    },
    hideEmoji() {
      this.eojiBool = false;
    },
    showEmoji(ev) {
      var oEvent = event || ev;

      if (oEvent.ctrlKey && oEvent.keyCode == 191) {
        console.log(123);
        this.eojiBool = !this.eojiBool;
      }
    },
    onFileDrop(ev) {
      if (ev.dataTransfer.files.length > 0) {
        this.uploadFile({ target: ev.dataTransfer });
      }
    },
    onPaste(ev) {
      if (ev.clipboardData.files.length > 0) {
        this.uploadFile({ target: ev.clipboardData });
      }
    },
    addUrl(url) {
      if (url.indexOf("/") == 0) {
        return this.URL + url;
      }
      return url;
    },
    loadMore() {
      if (this.loadingMore || this.messageLoading) {
        return;
      }
      this.loadingMore = true;
      this.loadMessageList();
    },
    loadMessageList() {
      // this.messages = [];
      let unreadIds = [];
      let oldHeight = 0;
      getMessageList({
        session_id: this.session.session_id,
        last_id: this.last_id,
        search: this.kw,
      })
        .then((res) => {
          if (this.loadingMore) {
            oldHeight = this.$refs.messageBody.scrollHeight;
            if (res.result.length == 0) {
              this.$toast.error("没有更多了", {
                timeout: 3000,
                position: "top-center",
              });
            }
          }
          // res.result.reverse();
          res.result.forEach((element) => {
            // this.last_id = element.msg_id < this.last_id ? element.msg_id : this.last_id;
            this.last_id = element.msg_id;
            if (!element.seat_read) {
              unreadIds.push(element.msg_id);
            }
            // if (element.msg_type == "img" || element.msg_type == "attachment") {
            //   element.content = JSON.parse(element.content);
            //   if (element.content.store_path.indexOf("/") == 0) {
            //     element.content.store_path = `${this.URL}${element.content.store_path}`;
            //   }
            // }

            this.messages.unshift(element);
          });
          console.log(this.messages);
          if (unreadIds.length > 0) {
            this.$socket.emit("readMessage", unreadIds);
          }
        })
        .finally(() => {
          this.messageLoading = false;
          const that = this;
          if (this.loadingMore) {
            this.loadingMore = false;
            this.$nextTick(function() {
              let nowHeight = that.$refs.messageBody.scrollHeight - oldHeight;
              that.$refs.messageBody.scrollTop = nowHeight;
            });
          } else {
            this.toBottom();
          }
        });
    },
    avatar(email) {
      return email ? `https://cdn.v2ex.com/gravatar/${md5(email)}` : "https://cdn.v2ex.com/gravatar/default";
    },
    closeMain() {
      eventBus.$emit("mainSwitch", false);
    },
    moreInfoToggle() {
      this.moreInfo = !this.moreInfo;
    },
    onSearch(e) {
      e.preventDefault();
    },
    searchToggle() {
      this.searchShow = !this.searchShow;
    },
    compositionend() {
      this.isTypewritingBool = false;
    },
    compositionstart() {
      this.isTypewritingBool = true;
    },
    keyDownToggle(e) {
      if (e.code == "Enter") {
        e.preventDefault();
        if (!e.ctrlKey && !this.isTypewritingBool) {
          this.sendTextMsg();
        } else {
          this.textarea += "\n";
          this.$refs.textarea.scrollTop = 9999999999;
        }
      }
    },
    scrollToggle(e) {
      this.autoBottom = e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight;
      e.target.scrollTop == 0 && this.loadMore();
    },
    sendMsg(msg_type, content) {
      this.$socket.emit("sendMessage", { msg_type, content, session_id: this.session.session_id });
    },
    sendTextMsg() {
      this.autoBottom = true;
      let content = this.textarea;
      if (!content.trim()) {
        return;
      }
      this.textarea = "";
      this.sendMsg("text", content);
    },
    typing(session_id, content) {
      this.$socket.emit("typing", {
        seat_name: this.$store.state.seat_name,
        session_id,
        content,
      });
    },
    toBottom() {
      const that = this;
      if (!this.autoBottom || !that.$refs.messageBody) {
        return;
      }
      this.$nextTick(function() {
        that.$refs.messageBody.scrollTop = that.$refs.messageBody.scrollHeight;
      });
    },
  },
  sockets: {
    // 报错信息
    messageFailure: function(message) {
      this.$toast.error(message, {
        timeout: 4000,
        position: "top-right",
      });
    },
    reciveMessage: function(message) {
      if (message.session_id == this.session.session_id) {
        // if (message.msg_type == "img" || message.msg_type == "attachment") {
        //   message.content = JSON.parse(message.content);
        //   if (message.content.store_path.indexOf("/") == 0) {
        //     message.content.store_path = `${this.URL}${message.content.store_path}`;
        //   }
        // }
        this.messages.push(message);
        this.toBottom();
      }
    },
    setSeat: function(message) {
      this.session.seat_email = message.seat_email;
      this.session.seat_id = message.seat_id;
      this.session.seat_name = message.seat_name;
      this.$toast.success("成功指派给" + message.seat_name, {
        timeout: 4000,
        position: "top-right",
      });
    },
    typing: function(data) {
      // console.log(data);
      if (data.sender_id == this.$store.state.seat_id) {
        return;
      }
      if (!data.content) {
        delete this.typings[`${data.sender_type}_${data.sender_id}`];
      } else {
        this.typings[`${data.sender_type}_${data.sender_id}`] = data;
        // this.typings[`${data.sender_type}_${data.sender_id}`] = data;
      }
      this.typings = Object.assign({}, this.typings);
    },
  },
};
</script>
<style scoped>
.list-group-item {
  border-bottom: 0px;
}
.Imgclose {
  cursor: pointer;
}
.Imgaction {
  width: 24px;
  height: 24px;
  border: 1px solid white;
  border-radius: 24px;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
  user-select: none;
}
.ShowImg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100000000;
}
.imgBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.imgBox img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}
.icon-back {
  font-size: 18px;
}
.icon-search {
  font-size: 18px;
}
.icon-more-horizen {
  font-size: 18px;
}
.icon-attachment {
  font-size: 22px;
}
.icon-goto {
  font-size: 22px;
}
.icon-close {
  font-size: 22px;
}
.icon-location {
  line-height: 16px;
}

.imgBlock {
  width: 7rem;
  height: 7rem;
  position: relative;
  display: inline-block;
  text-align: center;
}

.imgBlock > div {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;
  transform-style: preserve-3d;
  transform: translateZ(100px);
}

.imgBlock > div::before {
  content: "" !important;
  background-color: #2a2a2a !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 1 !important;
  animation-name: cc-seb1 !important;
  animation-duration: 1.5s !important;
  animation-timing-function: ease-in-out !important;
  animation-iteration-count: infinite !important;
  border-radius: 10px;
}

@keyframes cc-seb1 {
  0%,
  100% {
    opacity: 1;
    transform: translateZ(-100px);
  }
  50% {
    opacity: 0.5;
    transform: translateZ(-100px);
  }
}

.message-content,
.Gif-content {
  vertical-align: middle;
}
.message-content img,
.Gif-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto;
  cursor: pointer;
}
.chat-Gif {
  width: 100%;
  height: 16rem;
  background: #383f44;
  border-top: 1px solid #2b2b2f;
  position: relative;
  z-index: 1;
}
.chat-footer {
  z-index: 2;
}
.Gif-box {
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: center;
  padding-bottom: 3rem;
}
.emoji-items {
  display: inline-block;
  padding: 0.4rem 0.8rem;
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
}

.Gif-box::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.Gif-content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  padding: 0.4rem;
  border: 2px solid #383f44;
  margin: 0.2% 1%;
  border-radius: 0.2rem;
  cursor: pointer;
  overflow: hidden;
}
.Gif-content:hover {
  border: 2px solid white;
}
.close-Gif {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 2rem;
  height: 1.5rem;
  text-align: center;
  line-height: 1rem;
  cursor: pointer;
  color: white;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-Gif .icon-close {
  font-size: 14px;
}
.GIf-nav {
  background: #2b2b2f;
}

.GIf-nav div {
  cursor: pointer;
  user-select: none;
}

.my-icon {
  background: #f8f9fa;
  color: #665dfe !important;
}
.myActive {
  background: #665dfe;
  color: #f8f9fa !important;
}
.myClose {
  background: #ff337c !important;
  border-color: #ff337c !important;
}
.chat-footer {
  background: #323333;
}
.keyTip {
  display: grid;
  grid-template-columns: repeat(7, 15px);
  grid-template-rows: repeat(1, 30px);
  grid-gap: 5px;
}
.key {
  background-color: rgb(243, 243, 243);
  border: 2px solid black;
  border-radius: 5px;
  grid-column: span 2;
  font-size: 12px;
  text-align: center;
  padding-top: 4px;
  color: black;
}
.leftctrl {
  grid-column: span 3;
}
.keys {
  width: 16rem;
  height: 5rem;
  background: #2b2b2f;
  color: white;
  position: relative;
}
.loadline {
  position: absolute;
  width: 0%;
  height: 4px;
  bottom: 0;
  left: 0;
  background: #44a675;
}
.uploadLine {
  width: 0px;
  height: 4px;
  background: #44a675;
  transition: width 2s;
}
</style>

<style scoped>
.on {
  color: #665dfe !important;
}
.chat-body {
  position: relative;
}
.quick {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 4.5rem;
  left: 0;
  overflow: hidden;
}
.quick div {
  user-select: none;
}
.quick > div:nth-child(1) {
  flex: 1;
  white-space: nowrap;
  overflow: auto;
  position: relative;
  left: 100vw;
  transition: all 1s;
}
.quick > div:nth-child(1) div {
  display: inline-block;
  padding: 0rem 1rem;
  margin: 0rem 0.4rem;
  background: rgb(243, 243, 243);
  border-radius: 1rem;
  cursor: pointer;
}
.quick > div:nth-child(2) {
  width: 2rem;
  height: 2rem;
  line-height: 1.8rem;
  position: absolute;
  right: 1rem;
  border: 1px solid white;
  border-radius: 2rem;
  text-align: center;
  cursor: pointer;
}
.drawer {
  position: fixed;
  top: 0;
  left: 100vw;
  background: #323333;
  z-index: 10000;
  transition: all 1s;
}
.myFooter {
  padding-top: 1rem;
  border-top: 1px solid #2b2b2f;
  justify-content: flex-end;
  transition: all 1s;
}
.myLink {
  color: #665dfe;
  cursor: pointer;
}
</style>
