<template
  ><div>
    <div class="modal modal-lg-fullscreen fade" :class="{ show: modalShow, 'd-none': !modalDis, 'd-block': modalDis }" @click="close">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom">
        <div class="modal-content" @click.stop="void 0">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-0 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div class="toast-body">
                  <div class="form-group form-row">
                    <label class="col-sm-2 col-form-label text-center">Logo</label>
                    <div class="col d-flex align-items-end">
                      <div class="avatar mr-2" v-if="ImgUrl != undefined"><img :src="ImgUrl" style="border-radius: 0.4rem;"/></div>
                      <button type="button" class="btn btn-sm btn-primary" @click="upload">上传Logo</button>
                      <input type="file" ref="file" style="display:none" @change="showGif" />
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-sm-2 col-form-label text-center">应用名称</label>
                    <div class="col">
                      <input type="email" class="form-control text-white" placeholder="请输入应用名" v-model="appForm.app_name" />
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-sm-2 col-form-label text-center">邮箱</label>
                    <div class="col">
                      <input type="email" class="form-control text-white" placeholder="请输入邮箱" v-model="appForm.email" />
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-sm-2 col-form-label text-center">公告</label>
                    <div class="col">
                      <input type="email" class="form-control text-white" placeholder="请输入应用公告" v-model="appForm.notice" />
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-sm-2 col-form-label text-center">使用动图表情</label>
                    <div class="col">
                      <div class="form-check mb-0 d-flex aligin-items-center mt-1 pl-0">
                        <div class="custom-control custom-switch" style="user-select:none">
                          <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="appForm.emoji_status" />
                          <label class="custom-control-label" for="customSwitch1"></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-sm-2 col-form-label text-center">使用气泡</label>
                    <div class="col">
                      <div class="form-check mb-0 d-flex aligin-items-center mt-1 pl-0">
                        <div class="custom-control custom-switch" style="user-select:none">
                          <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="appForm.bubble_status" />
                          <label class="custom-control-label" for="customSwitch2"></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-sm-2 col-form-label text-center">使用App头像</label>
                    <div class="col">
                      <div class="form-check mb-0 d-flex aligin-items-center mt-1 pl-0">
                        <div class="custom-control custom-switch" style="user-select:none">
                          <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="appForm.avatar_status" />
                          <label class="custom-control-label" for="customSwitch3"></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-sm-2 col-form-label text-center">欢迎语</label>
                    <div class="col">
                      <div class="editor">
                        <div ref="toolbar" class="toolbar"></div>
                        <div ref="editor" class="text"></div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-sm-2 col-form-label text-center ">会话条件</label>
                    <div class="col">
                      <div class="form-row align-items-center" v-for="(data, index) in appForm.kv_setting" :key="data.id">
                        <div class="col-auto">
                          <input type="text" class="form-control mb-2 text-white" id="inlineFormInput" placeholder="key" v-model="data.key" />
                        </div>
                        <div class="col-auto">
                          <div class="input-group mb-2">
                            <input type="text" class="form-control text-white" id="inlineFormInputGroup" placeholder="name" v-model="data.name" />
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="form-check mb-2">
                            <input class="form-check-input" type="checkbox" :id="'autoSizingCheck' + index" v-model="data.required" />
                            <label class="form-check-label" :for="'autoSizingCheck' + index">
                              是否必填
                            </label>
                          </div>
                        </div>
                        <div class="col-auto">
                          <button data-v-d8a9e908="" type="button" class="close ml-2" style="margin-top:-16px" @click="delCondition(data)"><span data-v-d8a9e908="" aria-hidden="true">×</span></button>
                        </div>
                      </div>
                      <div>
                        <button type="submit" class="btn btn-primary" @click="addCondition">添加条件</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="toast-footer d-flex p-2">
                  <button type="submit" class="btn btn-success" @click="sureAdd">确定</button>
                  <button type="submit" class="btn btn-danger ml-2" @click="close">取消</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: modalShow, 'd-none': !modalDis }"></div>
  </div>
</template>
<script>
import { addAppList, editAppList } from "@/api/set";
import E from "wangeditor";

export default {
  data() {
    return {
      modalDis: true,
      modalShow: false,
      logoUp: false,
      ImgUrl: "",
      file: ""
    };
  },
  props: {
    title: String,
    appForm: Object
  },

  mounted() {
    console.log(this.appForm);
    this.modalDis = true;
    this.ImgUrl = this.appForm.avatar;
    setTimeout(() => {
      this.modalShow = true;
    }, 100);
    this.seteditor();
    this.$nextTick(() => {
      this.editor.txt.html(this.appForm.welcome);
    });
  },
  methods: {
    showGif: function(e) {
      // var vm = this;
      this.logoUp = true;
      for (var i = 0; i < e.target.files.length; i++) {
        this.file = e.target.files[i]; //取第1个文件
        var url = window.URL.createObjectURL(this.file);
        this.ImgUrl = url;
      }
      this.$refs.file.value = "";
    },
    upload() {
      this.$refs.file.click();
    },
    sure() {
      this.modalShow = false;
      setTimeout(() => {
        this.$emit("closeApp", "success");
      }, 300);
    },
    delCondition(item) {
      this.appForm.kv_setting.forEach((v, i) => {
        if (v.id == item.id) {
          this.appForm.kv_setting.splice(i, 1);
        }
      });
    },
    addCondition() {
      this.appForm.kv_setting.push({ id: this.appForm.kv_setting.length, key: "", name: "", required: true });
    },
    close: function() {
      this.modalShow = false;
      setTimeout(() => {
        this.$emit("closeApp");
      }, 300);
    },
    sureAdd() {
      var arr = ["app_key", "admin_id", "in_time", "clear_time", "to_oss", "oss_parameter"];
      let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
      let flag = 1;
      this.appForm.kv_setting.forEach((v, i) => {
        if (v.name == "") {
          this.$toast.error("第" + (i + 1) + "条会话条件name值不能为空", {
            timeout: 4000,
            position: "top-right"
          });
          flag = 0;
        }
        if (reg.test(v.key) == false) {
          this.$toast.error("第" + (i + 1) + "条会话条件key值错误", {
            timeout: 4000,
            position: "top-right"
          });
          flag = 0;
        }
      });
      if (flag == 0) {
        return;
      }
      var form = JSON.parse(JSON.stringify(this.appForm));
      form.emoji_status = form.emoji_status ? "1" : "0";
      form.bubble_status = form.bubble_status ? "1" : "0";
      form.avatar_status = form.avatar_status ? "1" : "0";
      form.kv_setting = JSON.stringify(form.kv_setting);
      var formData = new FormData();
      if (this.file != "") {
        formData.append("file", this.file);
      }
      for (let key in form) {
        if (arr.indexOf(key) == -1) {
          formData.append(key, form[key]);
        }
      }

      if (this.title == "添加应用") {
        addAppList(formData).then((res) => {
          if (res.code == 200) {
            this.$toast.success("添加成功", {
              timeout: 4000,
              position: "top-right"
            });
            this.modalShow = false;
            setTimeout(() => {
              this.$emit("closeApp", "success");
            }, 300);
          } else {
            this.$toast.error(res.msg, {
              timeout: 4000,
              position: "top-right"
            });
          }
        });
      } else {
        editAppList(formData).then((res) => {
          if (res.code == 200) {
            this.$toast.success("修改成功", {
              timeout: 4000,
              position: "top-right"
            });
            this.modalShow = false;
            setTimeout(() => {
              this.$emit("closeApp", "success");
            }, 300);
          } else {
            this.$toast.error(res.msg, {
              timeout: 4000,
              position: "top-right"
            });
          }
        });
      }
    },
    // 富文本
    seteditor() {
      let that = this;
      this.editor = new E(this.$refs.toolbar, this.$refs.editor);
      this.editor.config.uploadImgShowBase64 = false; // base 64 存储图片
      this.editor.config.uploadImgMaxSize = 1024 * 1024; // 将图片大小限制为 2M
      this.editor.config.customUploadImg = function(resultFiles) {
        console.log(resultFiles);
        let formDate = new FormData();
        formDate.append("file", resultFiles[0]);
        // upload(formDate).then((res) => {
        //   insertImgFn(that.URL + res.result.url);
        // });
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法

        // 上传图片，返回结果，将图片插入到编辑器中
      };

      // 配置菜单
      this.editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        // "list", // 列表
        "justify", // 对齐方式
        // "quote", // 引用
        "emoticon", // 表情
        // "image", // 插入图片
        // "table", // 表格
        "code", // 插入代码
        "undo" // 撤销
        // "redo", // 重复
        // "fullscreen", // 全屏
      ];

      this.editor.config.uploadImgHooks = {
        customInsert: (insertImg, result) => {
          // 图片上传成功，插入图片的回调
          //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
          // console.log(result.data[0].url)
          //insertImg()为插入图片的函数
          //循环插入图片
          // for (let i = 0; i < 1; i++) {
          // console.log(result)
          let url = that.URL + result.url;
          insertImg(url);
          // }
        }
      };
      this.editor.config.onchange = (html) => {
        this.appForm.welcome = html;
      };
      // 创建富文本编辑器
      this.editor.create();
    }
  }
};
</script>

<style scoped>
.modal-content {
  min-width: 800px;
}
.LogoImg {
  max-width: 6rem;
  max-height: 6rem;
  cursor: pointer;
}
.LogoImg img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
