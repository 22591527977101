import axios from "axios";
// import store from "@/store";
// import storage from 'store'
// import notification from "ant-design-vue/es/notification";
import { VueAxios } from "./axios";
// import { useToast, POSITION } from "vue-toastification";
// import { ACCESS_TOKEN } from '@/store/mutation-types'
// import local from "@/utils/local";
// import { message } from "ant-design-vue";
import context from "../main";

// const toast = useToast();

// 创建 axios 实例
const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000 // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  context.$toast.error("网络错误\n" + error.message, {
    timeout: 4000,
    position: "top-right"
  });
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  const app_id = localStorage.getItem("app_id");
  console.log(config)

  if (token) {
    config.headers.Authorization = token;
  }
  if (app_id) {
    if (config.method == 'get') {
      if (config.params == undefined) {
        config.params = {}
      }
      config.params.appwd = app_id
    } else {
      if (config.params == undefined) {
        config.params = {}
      }
      config.params.appwd = app_id
    }
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data.message) {
    if (response.data.code == 401) {
      context.$toast.error(response.data.message, {
        timeout: 4000,
        position: "top-right"
      });
      localStorage.removeItem("token");
      context.$router.push({ path: "/login" });
    } else if (response.data.code != 200) {
      context.$toast.error(response.data.message, {
        timeout: 4000,
        position: "top-right"
      });
    } else {
      context.$toast.success(response.data.message, {
        timeout: 4000,
        position: "top-right"
      });
    }
  }
  return response.data;
}, errorHandler);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request);
  }
};
export default request;

export { installer as VueAxios, request as axios };
