import request from "@/utils/request";

const expenseApi = {
  clientInfo: "/seat/clientInfo",
  login: "/seat/login",
  appList: "/seat/appList",
  seatInfo: "/seat/seatInfo"
};

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return request({
    url: expenseApi.login,
    method: "post",
    data: parameter
  });
}
export function appList(parameter) {
  return request({
    url: expenseApi.appList,
    method: "get",
    params: parameter
  });
}
export function getSeatInfo(parameter) {
  return request({
    url: expenseApi.seatInfo,
    method: "get",
    params: parameter
  });
}
// 用户信息
export function getClientInfo(parameter) {
  return request({
    url: expenseApi.clientInfo,
    method: "get",
    params: parameter
  });
}
