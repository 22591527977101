<template>
  <div class="main-layout card-bg-1">
    <div class="container d-flex flex-column">
      <div class="row no-gutters text-center align-items-center justify-content-center min-vh-100">
        <div class="col-12 col-md-6 col-lg-5 col-xl-4">
          <h1 class="font-weight-bold">随客云客服</h1>
          <p class="text-dark mb-3">简单又不简单的客服系统</p>
          <form class="mb-3" @submit="login">
            <div class="form-group">
              <label for="email" class="sr-only">电子邮箱</label>
              <input type="email" class="form-control form-control-md" v-model="email" placeholder="请输入电子邮箱" />
            </div>
            <div class="form-group">
              <label for="password" class="sr-only">密码</label>
              <input type="password" class="form-control form-control-md" v-model="password" placeholder="请输入您的密码" />
            </div>
            <div class="form-group d-flex justify-content-between">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" checked="" id="checkbox-remember" />
                <label class="custom-control-label text-muted font-size-sm" for="checkbox-remember">信任本台电脑</label>
              </div>
            </div>
            <button class="btn btn-primary btn-lg btn-block text-uppercase font-weight-semibold" type="submit">登录</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/basic";
import md5 from "md5";
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    login(event) {
      event.preventDefault();
      console.log(this.email);
      login({ email: this.email, password: md5(`${this.password}suikefu`) }).then((res) => {
        if (res.code == 200) {
          console.log(res.result);
          localStorage.setItem("token", res.result.token);
          localStorage.setItem("seat_id", res.result.seatInfo.seat_id);
          this.$store.commit("setUserInfo", { ...res.result.seatInfo, email: this.email });
          let that = this;
          setTimeout(function() {
            that.$router.push({ path: "/" });
          }, 1e3);
        }
      });
    },
  },
};
</script>
