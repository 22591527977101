<template
  ><div>
    <div class="modal modal-lg-fullscreen fade" :class="{ show: modalShow, 'd-none': !modalDis, 'd-block': modalDis }" @click="close">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom">
        <div class="modal-content " @click.stop="void 0">
          <div class="modal-header">
            <h5 class="modal-title">添加表情包</h5>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <!-- List Group Start -->
                <ul class="list-group list-group-flush">
                  <div>
                    <button type="button" class="btn btn-primary mr-2 mb-2" @click="addGifs">选择表情(可多选)</button>
                    <input type="file" style="display: none" ref="uploadFile" multiple @change="showGif" />
                  </div>
                  <!-- List Group Item Start -->
                  <li ref="GifShow" class="GifShow">
                    <div class="addImgBlock" v-for="(data, index) in GifData" :key="index">
                      <img :src="data.url" alt="" />
                      <input type="text" placeholder="关键字" class="form-control search" v-model="data.kw" />
                    </div>
                  </li>
                  <button type="button" class="btn btn-primary mr-2 mb-2" @click="sure(0)">确定</button>

                  <!-- List Group Item End -->
                </ul>
                <!-- List Group End -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: modalShow, 'd-none': !modalDis }"></div>
  </div>
</template>
<script>
import md5 from "md5";
import { addEmojiList } from "@/api/set";

export default {
  data() {
    this.md5 = md5;
    return {
      modalDis: true,
      modalShow: false,
      GifData: []
    };
  },
  filters: {
    splitName: function(val) {
      return val.substr(0, 2);
    }
  },
  mounted() {
    this.modalDis = true;
    setTimeout(() => {
      this.modalShow = true;
    }, 100);
  },
  methods: {
    showGif: function(e) {
      // var vm = this;
      var formData = new FormData();
      formData.append("session_id", this.session_id);
      for (var i = 0; i < e.target.files.length; i++) {
        var file = e.target.files[i]; //取第1个文件
        var url = window.URL.createObjectURL(file);
        this.GifData.push({ file, kw: file.name.split(".")[0], url });
        // 回显当前图片
      }
      this.$refs.uploadFile.value = null;
    },
    sure(i) {
      if (this.GifData.length == 0) {
        this.$toast.error("请选择表情包", {
          timeout: 4000,
          position: "top-right"
        });
        return;
      }
      if (i >= this.GifData.length) {
        this.GifData = [];
        this.modalShow = false;
        this.$emit("chooseApp", "success");
        return;
      }
      var formData = new FormData();
      formData.append("app_id", localStorage.getItem("app_id"));
      formData.append("file", this.GifData[i].file);
      formData.append("kw", this.GifData[i].kw);

      addEmojiList(formData).then((res) => {
        if (res.code == 200) {
          this.$toast.success("第" + (Number(i) + 1) + "张表情包添加成功", {
            timeout: 4000,
            position: "top-right"
          });
          this.sure(i + 1);
        }
      });
    },
    addGifs() {
      this.$refs.uploadFile.click();
    },
    close: function() {
      this.modalShow = false;
      setTimeout(() => {
        this.$emit("chooseApp");
      }, 300);
    }
  }
};
</script>
<style>
.GifShow {
  display: block;
  text-align: center;
}
.list-group-item-hover:hover {
  background: #272727;
  cursor: pointer;
}
.addImgBlock {
  width: 6rem;
  position: relative;
  display: inline-flex;
  margin: 0rem 0.75rem 0.75rem 0rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.addImgBlock .form-control {
  caret-color: #adb5bd;
  background-color: #2a2a2a;
  border-color: #2a2a2a;
  margin-bottom: 2rem;
}
.addImgBlock img {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
}
</style>
