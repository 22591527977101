<template>
  <div class="card flex-fill">
    <div class="card-header d-flex align-items-center">
      <button class="btn btn-secondary btn-icon btn-minimal btn-sm text-white d-xl-none mr-2" @click="closeMain">
        <i class="iconfont icon-back"></i>
      </button>
      <div class="text-white">常见问题</div>
    </div>
    <div class="card-body mySroll">
      <button type="button" class="btn btn-primary mb-3" @click="showProblem">添加常见问题</button>
      <table class="table table-striped text-center">
        <thead>
          <tr class="text-white">
            <th scope="col">问题</th>
            <th scope="col">答案</th>
            <th scope="col">关键词</th>
            <th scope="col">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-white" v-for="data in problemList" :key="data.id">
            <td>{{ data.question }}</td>
            <td>{{ data.answer }}</td>
            <td>{{ data.kw }}</td>
            <td>
              <button type="button" class="btn btn-primary btn-sm mr-2" @click="edit(data)">
                修改
              </button>
              <button type="button" class="btn btn-danger btn-sm" @click="del(data.id)">
                删除
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ProblemModal v-if="problemBool" @closeApp="closeApp" :problemObj="problemObj" :title="title" />
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
import ProblemModal from "@/components/modal/problemModal";
import { getProblemList, delProblemList } from "@/api/set";
// , addProblemList, editProblemList,
export default {
  created() {
    this.getList();
  },
  components: {
    ProblemModal
  },
  data() {
    return {
      problemBool: false,
      title: "添加常见问题",
      problemList: [],
      problemObj: {
        answer: "",
        kw: "",
        question: ""
      }
    };
  },
  methods: {
    edit(item) {
      this.title = "修改常见问题";
      this.problemObj = JSON.parse(JSON.stringify(item));
      this.problemBool = true;
    },
    del(id) {
      delProblemList({ app_id: localStorage.getItem("app_id"), id }).then((res) => {
        if (res.code == 200) {
          this.$toast.success("删除成功", {
            timeout: 4000,
            position: "top-right"
          });
          this.getList();
        }
      });
    },
    closeApp(data) {
      if (data == "success") {
        this.$toast.success("常见问题添加成功", {
          timeout: 4000,
          position: "top-right"
        });
        this.getList();
      }
      this.problemBool = !this.problemBool;
    },
    getList() {
      getProblemList({ app_id: localStorage.getItem("app_id") }).then((res) => {
        this.problemList = res.result;
        console.log(res);
      });
    },
    showProblem() {
      this.problemObj = {
        answer: "",
        kw: "",
        question: ""
      };
      this.problemBool = true;
    },
    closeMain() {
      eventBus.$emit("mainSwitch", false);
    }
  }
};
</script>

<style lang="less">
.mySroll {
  tr td {
    white-space: nowrap;
  }
  overflow: auto;
}

.mySroll::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}
.mySroll::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.mySroll::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #ededed;
}
</style>
