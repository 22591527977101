<template>
  <div class="d-flex flex-column h-100">
    <div class="hide-scrollbar h-100" id="callContactsList">
      <div class="sidebar-header sticky-top p-2">
        <div class="d-flex justify-content-between align-items-center"><h5 class="font-weight-semibold mb-0">设置</h5></div>
      </div>
      <!-- Call Contact List Start 人 -->
      <ul class="contacts-list" id="callLogTab" data-call-list="">
        <!-- Call Item Start -->
        <li
          v-for="(data, index) in menuList"
          :key="index"
          v-show="!data.priv || ($store.state.seat_id == 1 && (data.priv == 'app' || data.priv == 'set')) || ($store.state.priv[data.priv] && $store.state.priv[data.priv].read == 1)"
          class="contacts-item incoming"
          :class="{ active: set_id == data.path }"
          @click="seatDetail(data)"
        >
          <router-link :to="{ path: `/settings/${data.path}` }">
            <div class="contacts-link">
              <div class="my-avatar">
                <svg viewBox="0 0 1024 1024" width="100%" height="100%">
                  <path :d="data.icon" fill="#fff"></path>
                </svg>
              </div>
              <div class="contacts-content">
                <h6 class="chat-name text-truncate m-0">{{ data.title }}</h6>
                <p class="mb-0 text-muted mt-1">{{ data.subTitle }}</p>
              </div>
            </div>
          </router-link>
        </li>
        <li class="contacts-item incoming d-xl-none" @click="chooseApp">
          <div class="contacts-link">
            <div class="my-avatar">
              <svg t="1617262566694" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2051">
                <path d="M990.4128 478.4128H547.6352V35.6352h442.7776v442.7776z m-403.8656-38.912h364.9536V74.5472H586.5472v364.9536zM480.256 988.3648H37.4784V545.5872h442.7776v442.7776z m-403.8656-38.912h364.9536V584.4992H76.3904v364.9536z" fill="#fff" p-id="2052"></path>
                <path
                  d="M460.8 988.3648c-10.6496 0-19.456-8.8064-19.456-19.456s8.8064-19.456 19.456-19.456c220.7744 0 400.384-179.6096 400.384-400.384 0-10.6496 8.8064-19.456 19.456-19.456s19.456 8.8064 19.456 19.456c0 117.3504-45.6704 227.5328-128.6144 310.6816-82.944 82.944-193.3312 128.6144-310.6816 128.6144zM144.384 494.1824c-10.6496 0-19.456-8.8064-19.456-19.456 0-117.3504 45.6704-227.5328 128.6144-310.6816 82.944-82.944 193.3312-128.6144 310.6816-128.6144 10.6496 0 19.456 8.8064 19.456 19.456s-8.8064 19.456-19.456 19.456C343.4496 74.5472 163.84 254.1568 163.84 474.7264c0 10.8544-8.6016 19.456-19.456 19.456z"
                  fill="#fff"
                  p-id="2053"
                ></path>
                <path
                  d="M979.3536 645.9392c-4.9152 0-10.0352-1.8432-13.7216-5.7344l-86.016-86.016-86.016 86.016a19.406848 19.406848 0 0 1-27.4432-27.4432l89.088-89.088c6.5536-6.5536 15.1552-10.24 24.576-10.24s18.0224 3.6864 24.576 10.24l89.088 89.088c7.5776 7.5776 7.5776 19.8656 0 27.4432-4.3008 3.8912-9.216 5.7344-14.1312 5.7344zM144.384 507.4944c-9.216 0-18.0224-3.6864-24.576-10.24L30.72 408.1664a19.406848 19.406848 0 0 1 27.4432-27.4432l86.016 86.016 86.016-86.016a19.406848 19.406848 0 0 1 27.4432 27.4432l-89.088 89.088c-6.144 6.7584-14.7456 10.24-24.1664 10.24z"
                  fill="#fff"
                  p-id="2054"
                ></path>
              </svg>
            </div>
            <div class="contacts-content">
              <h6 class="chat-name text-truncate m-0">切换应用</h6>
              <p class="mb-0 text-muted mt-1">切换个人的应用</p>
            </div>
          </div>
        </li>
        <!-- Call Item End -->
      </ul>
      <!-- Call Contact List Start -->
    </div>
    <ChooseApp v-if="modalChooseApp" @chooseApp="chooseApp" />
  </div>
</template>
<script>
import eventBus from "@/utils/eventBus";
import ChooseApp from "@/components/modal/chooseApp.vue";

export default {
  components: {
    ChooseApp
  },
  created() {
    var path = this.$route.path.split("/")[2];
    if (path != undefined) {
      this.set_id = path;
      this.menuList.forEach((element) => {
        if (element.path == path) {
          setTimeout(() => {
            eventBus.$emit("clickSet", element);
            this.$store.commit("clickSet", element);
          });
        }
      });
    }
  },

  data() {
    return {
      modalChooseApp: false,
      set_id: "",
      menuList: [
        {
          path: "user",
          icon:
            "M512 224c-53.0432 0-96 43.008-96 96v42.6496a96 96 0 1 0 192 0V320c0-53.0432-43.008-96-96-96z m111.104 253.7984a330.8032 330.8032 0 0 1 219.5456 311.552c0 41.216-33.4336 74.6496-74.6496 74.6496H256a74.6496 74.6496 0 0 1-74.6496-74.6496A330.8032 330.8032 0 0 1 400.896 477.7984a159.5392 159.5392 0 0 1-48.896-115.1488V320a160 160 0 0 1 320 0v42.6496c0 45.2096-18.7392 86.016-48.896 115.1488z m-111.104 44.8512a266.6496 266.6496 0 0 0-266.6496 266.7008c0 5.888 4.7616 10.6496 10.6496 10.6496h512a10.6496 10.6496 0 0 0 10.6496-10.6496A266.6496 266.6496 0 0 0 512 522.6496z",
          title: "个人设置",
          subTitle: "姓名、邮箱、密码"
        },
        {
          path: "application",
          icon:
            "M352 448h-256A96 96 0 0 1 0 352v-256A96 96 0 0 1 96 0h256A96 96 0 0 1 448 96v256A96 96 0 0 1 352 448z m-256-384a32 32 0 0 0-32 32v256a32 32 0 0 0 32 32h256a32 32 0 0 0 32-32v-256a32 32 0 0 0-32-32zM800 448A224 224 0 1 1 1024 224 224 224 0 0 1 800 448z m0-384A160 160 0 1 0 960 224 160 160 0 0 0 800 64zM352 1024h-256A96 96 0 0 1 0 928v-256A96 96 0 0 1 96 576h256A96 96 0 0 1 448 672v256A96 96 0 0 1 352 1024z m-256-384a32 32 0 0 0-32 32v256a32 32 0 0 0 32 32h256a32 32 0 0 0 32-32v-256a32 32 0 0 0-32-32zM928 1024h-256A96 96 0 0 1 576 928v-256A96 96 0 0 1 672 576h256a96 96 0 0 1 96 96v256a96 96 0 0 1-96 96z m-256-384a32 32 0 0 0-32 32v256a32 32 0 0 0 32 32h256a32 32 0 0 0 32-32v-256a32 32 0 0 0-32-32z",
          title: "应用管理",
          subTitle: "管理应用的地方",
          priv: "app"
        },
        {
          path: "system",
          icon:
            "M576 800a96 96 0 0 1 96-96h64a96 96 0 0 1 96 96h128a32 32 0 0 1 0 64h-128a96 96 0 0 1-96 96h-64a96 96 0 0 1-96-96H64a32 32 0 0 1 0-64h512z m96-32a32 32 0 0 0-32 32v64a32 32 0 0 0 32 32h64a32 32 0 0 0 32-32v-64a32 32 0 0 0-32-32h-64zM544 160a96 96 0 0 1 96-96h64a96 96 0 0 1 96 96h160a32 32 0 0 1 0 64h-160a96 96 0 0 1-96 96h-64a96 96 0 0 1-96-96H64a32 32 0 1 1 0-64h480z m96-32a32 32 0 0 0-32 32v64a32 32 0 0 0 32 32h64a32 32 0 0 0 32-32V160a32 32 0 0 0-32-32h-64zM192 480a96 96 0 0 1 96-96h64a96 96 0 0 1 96 96h512a32 32 0 0 1 0 64H448a96 96 0 0 1-96 96H288a96 96 0 0 1-96-96H64a32 32 0 0 1 0-64h128z m96-32a32 32 0 0 0-32 32v64a32 32 0 0 0 32 32h64a32 32 0 0 0 32-32v-64a32 32 0 0 0-32-32H288z",
          title: "系统设置",
          subTitle: "管理系统的地方",
          priv: "set"
        },
        {
          path: "quick",
          icon:
            "M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32z m-40 728H184V184h656v656zM370.8 554.4c-54.6 0-98.8 44.2-98.8 98.8s44.2 98.8 98.8 98.8 98.8-44.2 98.8-98.8v-42.4h84.7v42.4c0 54.6 44.2 98.8 98.8 98.8s98.8-44.2 98.8-98.8-44.2-98.8-98.8-98.8h-42.4v-84.7h42.4c54.6 0 98.8-44.2 98.8-98.8 0-54.6-44.2-98.8-98.8-98.8s-98.8 44.2-98.8 98.8v42.4h-84.7v-42.4c0-54.6-44.2-98.8-98.8-98.8S272 316.2 272 370.8s44.2 98.8 98.8 98.8h42.4v84.7h-42.4z m42.4 98.8c0 23.4-19 42.4-42.4 42.4s-42.4-19-42.4-42.4 19-42.4 42.4-42.4h42.4v42.4z m197.6-282.4c0-23.4 19-42.4 42.4-42.4s42.4 19 42.4 42.4-19 42.4-42.4 42.4h-42.4v-42.4z m0 240h42.4c23.4 0 42.4 19 42.4 42.4s-19 42.4-42.4 42.4-42.4-19-42.4-42.4v-42.4zM469.6 469.6h84.7v84.7h-84.7v-84.7z m-98.8-56.4c-23.4 0-42.4-19-42.4-42.4s19-42.4 42.4-42.4 42.4 19 42.4 42.4v42.4h-42.4z",
          title: "快捷输入",
          subTitle: "定义快捷输入的文字",
          priv: "fastReply"
        },
        {
          path: "problem",
          icon:
            "M917.331968 63.870976l-817.54112 0c-21.486592 0-39.617536 18.146304-39.617536 39.61344l0 817.559552c0 21.469184 18.130944 40.081408 39.617536 40.081408l817.54112 0c21.481472 0 39.135232-18.612224 39.135232-40.081408L956.4672 103.484416C956.466176 82.01728 938.812416 63.870976 917.331968 63.870976zM557.95712 829.887488 415.726592 829.887488 415.726592 693.866496l142.230528 0L557.95712 829.887488zM612.84352 522.048512c-43.85792 32.526336-63.481856 66.817024-63.481856 101.658624l0 26.249216L418.118656 649.956352l0-34.839552c0-56.794112 24.67328-100.445184 81.60768-139.839488 49.973248-34.582528 74.032128-73.022464 72.544256-113.112064-1.855488-50.113536-26.249216-78.749696-72.544256-78.749696-53.454848 0-83.999744 40.090624-95.921152 116.453376L273.02912 399.868928c19.090432-136.976384 93.539328-205.165568 226.697216-203.316224 139.844608 1.969152 209.257472 56.297472 214.774784 159.885312C717.574144 414.175232 683.528192 469.627904 612.84352 522.048512z",
          title: "问答管理",
          subTitle: "管理一些常见的问题以及回答",
          priv: "problem"
        },
        {
          path: "emoji",
          icon:
            "M746.666667 512a234.666667 234.666667 0 1 1 0 469.333333 234.666667 234.666667 0 0 1 0-469.333333zM512 85.290667c235.690667 0 426.752 191.061333 426.752 426.752 0 11.221333-0.426667 22.357333-1.28 33.365333a277.589333 277.589333 0 0 0-62.933333-44.885333 362.709333 362.709333 0 1 0-373.973334 374.058666c11.946667 23.04 27.093333 44.202667 44.8 62.890667-10.965333 0.853333-22.101333 1.28-33.365333 1.28-235.690667 0-426.752-191.061333-426.752-426.709333C85.248 276.352 276.309333 85.248 512 85.248z m234.666667 512l-3.84 0.341333a21.333333 21.333333 0 0 0-17.152 17.152L725.333333 618.666667V725.333333L618.581333 725.333333l-3.84 0.341334a21.333333 21.333333 0 0 0-17.152 17.152l-0.341333 3.84 0.341333 3.84a21.333333 21.333333 0 0 0 17.152 17.152l3.84 0.341333H725.333333v106.794667l0.341334 3.84a21.333333 21.333333 0 0 0 17.152 17.152l3.84 0.341333 3.84-0.341333a21.333333 21.333333 0 0 0 17.152-17.152l0.341333-3.84V768l106.794667 0.042667 3.84-0.341334a21.333333 21.333333 0 0 0 17.152-17.152l0.384-3.84-0.341334-3.84a21.333333 21.333333 0 0 0-17.194666-17.152l-3.84-0.341333H768v-106.666667l-0.341333-3.84a21.333333 21.333333 0 0 0-17.152-17.194666L746.666667 597.333333z m-385.621334 33.493333a191.744 191.744 0 0 0 112.213334 69.376 275.626667 275.626667 0 0 0-3.370667 64.426667 255.274667 255.274667 0 0 1-159.146667-94.208 32 32 0 0 1 50.304-39.594667zM384 373.333333a53.333333 53.333333 0 1 1 0 106.624A53.333333 53.333333 0 0 1 384 373.333333z m256 0a53.333333 53.333333 0 1 1 0 106.624 53.333333 53.333333 0 0 1 0-106.624z",
          title: "表情包管理",
          subTitle: "管理动态表情的地方",
          priv: "emoji"
        }
      ]
    };
  },

  methods: {
    chooseApp() {
      this.modalChooseApp = !this.modalChooseApp;
    },
    typeDropdownToggle() {
      this.typeDropdown = !this.typeDropdown;
    },
    seatDetail(item) {
      this.set_id = item.path;
      eventBus.$emit("clickSet", item);
      this.$store.commit("clickSet", item);
    }
  }
};
</script>
<style>
.my-avatar {
  width: 1.8rem;
  height: 1.8rem;
  display: inline-block;
  position: relative;
  color: white;
}
</style>
