<template>
  <div class="card flex-fill">
    <div class="card-header d-flex align-items-center">
      <button class="btn btn-secondary btn-icon btn-minimal btn-sm text-white d-xl-none mr-2" @click="closeMain">
        <i class="iconfont icon-back"></i>
      </button>
      <div class="text-white">应用管理</div>
    </div>
    <div class="card-body h-100 w-100 d-flex align-items-center justify-content-center text-white" v-if="loading">
      加载中...
    </div>
    <div class="card-body mySroll" v-else>
      <button type="button" class="btn btn-primary mb-3" @click="addApp">添加应用</button>
      <table class="table table-striped text-center">
        <thead>
          <tr class="text-white">
            <th scope="col">应用ID</th>
            <th scope="col">应用名</th>
            <th scope="col">app_key</th>
            <th scope="col">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-white" v-for="data in appLists" :key="data.app_id">
            <th scope="row">{{ data.app_id }}</th>
            <td>{{ data.app_name }}</td>

            <td>
              {{ data.app_key }}
            </td>
            <td>
              <button type="button" class="btn btn-primary btn-sm mr-2" @click="editApp(data)">
                修改
              </button>
              <button type="button" class="btn btn-danger btn-sm" @click="delApp(data.app_id)">
                删除
              </button>
              <button type="button" class="btn btn-success btn-sm ml-2" @click="showHtml(data)">
                获取代码
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <SystemModal :appForm="appForm" v-if="maskBool" :title="modalTitle" @closeApp="closeApp" />
    <!-- <div class="mask w-100 h-100 d-flex align-items-center justify-content-center" v-if="maskBool">
      <div class="mask-aleart text-white"></div>
    </div> -->
    <ShowHtml v-if="htmlBool" @chooseApp="chooseApp" :html="html" :url="url" />
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
import { getAppList, delAppList, getSystemConfig } from "@/api/set";
import ShowHtml from "@/components/modal/showHtml";
import SystemModal from "@/components/modal/systemModal";

export default {
  created() {
    this.appList();
  },
  components: {
    ShowHtml,
    SystemModal,
  },
  data() {
    return {
      modalTitle: "添加应用",
      maskBool: false,
      htmlBool: false,
      appLists: [],
      appForm: {
        app_name: "",
        email: "",
        kv_setting: [],
        welcome: "",
        emoji_status: 1,
        bubble_status: 1,
        avatar_status: 1,
      },
      html: "",
      url: "",
      loading: false,
    };
  },
  methods: {
    closeMain() {
      eventBus.$emit("mainSwitch", false);
    },
    chooseApp() {
      this.html = "";
      this.htmlBool = !this.htmlBool;
    },
    closeApp(data) {
      if (data == "success") {
        this.appList();
      }
      this.maskBool = !this.maskBool;
    },
    appList() {
      this.loading = true;
      getAppList().then((res) => {
        this.loading = false;
        console.log(res.result);
        res.result.forEach((v) => {
          try {
            v.kv_setting = JSON.parse(v.kv_setting);
          } catch (err) {
            console.log(err);
          }
        });
        this.appLists = res.result;
        console.log(res);
      });
    },

    showHtml(item) {
      getSystemConfig().then((res) => {
        console.log(res);
        this.url = `${res.result.chat_url}chat/${item.app_key}`;
        this.html += "<script>";
        this.html += `
  window.BODY_COLOR = "#746adb";
  window.IFRAME = false;
  window.APP_KEY = "${item.app_key}";
  (function () {
      d = document
      v = d.createElement("div")
      v.id = 'SKY-app'
      d.getElementsByTagName("body")[0].appendChild(v)
      s = d.createElement('script')
      s.src = '${res.result.js_path}bundle.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
  })()
`;
        this.html += "</scr";
        this.html += "ipt>";
        //
        this.htmlBool = true;
      });
    },
    addApp() {
      this.modalTitle = "添加应用";
      this.maskBool = true;
      this.appForm = {
        app_name: "",
        email: "",
        kv_setting: [],
        welcome: "",
        emoji_status: 1,
        bubble_status: 1,
        avatar_status: 1,
      };
    },

    editApp(data) {
      this.modalTitle = "修改应用";
      console.log(data);
      Object.assign(this.appForm, data);
      console.log(this.appForm);
      this.maskBool = true;
    },

    delApp(id) {
      delAppList({ app_id: id }).then((res) => {
        if (res.code == 200) {
          this.appList();
          this.$toast.success("应用删除成功", {
            timeout: 4000,
            position: "top-right",
          });
        }
      });
    },
  },
};
</script>

<style lang="less">
.mySroll {
  tr td {
    white-space: nowrap;
  }
  overflow: auto;
}

.mySroll::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}
.mySroll::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.mySroll::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #ededed;
}
</style>
