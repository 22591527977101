import request from "@/utils/request";

const expenseApi = {
    emoji: "/settings/emoji",
    app: "/settings/app",
    self: "/settings/setSelf",
    out: '/settings/outOrg',
    avatar: "/seat/upAvatar",
    quick: "/settings/fastReply",
    problem: "/settings/problem",
    systemConfig: "/settings/systemConfig"
};
// emoji
export function getEmojiList(parameter) {
    return request({
        url: expenseApi.emoji,
        method: "get",
        params: parameter
    });
}
export function addEmojiList(parameter) {
    return request({
        url: expenseApi.emoji,
        method: "put",
        data: parameter
    });
}

export function saveEmojiKw(parameter) {
    return request({
        url: expenseApi.emoji,
        method: "post",
        data: parameter
    });
}

// 删除
export function editEmojiList(parameter) {
    return request({
        url: expenseApi.emoji,
        method: "delete",
        params: parameter
    });
}

// app
export function getAppList(parameter) {
    return request({
        url: expenseApi.app,
        method: "get",
        params: parameter
    });
}
export function addAppList(parameter) {
    return request({
        url: expenseApi.app,
        method: "put",
        data: parameter
    });
}

export function editAppList(parameter) {
    return request({
        url: expenseApi.app,
        method: "post",
        data: parameter
    });
}

export function delAppList(parameter) {
    return request({
        url: expenseApi.app,
        method: "delete",
        params: parameter
    });
}

// 修改个人信息

export function setSelf(parameter) {
    return request({
        url: expenseApi.self,
        method: "post",
        data: parameter
    });
}
// 设置头像
export function setAvatat(parameter) {
    return request({
        url: expenseApi.avatar,
        method: "post",
        data: parameter
    });
}

// 退出组织

export function outOrg(parameter) {
    return request({
        url: expenseApi.out,
        method: "delete",
        params: parameter
    });
}


// 快捷消息
export function getQuickList(parameter) {
    return request({
        url: expenseApi.quick,
        method: "get",
        params: parameter
    });
}
export function addQuickList(parameter) {
    return request({
        url: expenseApi.quick,
        method: "put",
        data: parameter
    });
}

export function editQuickList(parameter) {
    return request({
        url: expenseApi.quick,
        method: "post",
        data: parameter
    });
}

export function delQuickList(parameter) {
    return request({
        url: expenseApi.quick,
        method: "delete",
        params: parameter
    });
}
// 常见问题
export function getProblemList(parameter) {
    return request({
        url: expenseApi.problem,
        method: "get",
        params: parameter
    });
}
export function addProblemList(parameter) {
    return request({
        url: expenseApi.problem,
        method: "put",
        data: parameter
    });
}

export function editProblemList(parameter) {
    return request({
        url: expenseApi.problem,
        method: "post",
        data: parameter
    });
}

export function delProblemList(parameter) {
    return request({
        url: expenseApi.problem,
        method: "delete",
        params: parameter
    });
}

export function getSystemConfig(parameter) {
    return request({
        url: expenseApi.systemConfig,
        method: "get",
        params: parameter
    });
}
export function setSystemConfig(parameter) {
    return request({
        url: expenseApi.systemConfig,
        method: "post",
        data: parameter
    });
}