<template>
  <div class="crm w-100">
    <welcome v-if="crmCheck == false" :title="'请选择左侧客户开始操作'" />
    <div class="container-fluid h-100 w-100">
      <div class="row justify-content-between h-100 w-100 hide-scrollbar my-row">
        <div class="card-body flex-fill" style="flex:1 !important;">
          <div class="card my-info d-flex flex-column">
            <div class="card-body">
              <div class="d-flex flex-column align-items-center">
                <div class="avatar avatar-lg mb-2">
                  <img :src="client.avatar" draggable="false" />
                </div>
                <h4 class="mb-0">{{ client.client_name }}</h4>
                <div class="mt-1 text-muted">
                  <div class="avatar my-client-avatar" :class="{ 'avatar-online': client.online, 'avatar-offline': !client.online }"></div>
                  {{ client.online == 0 ? "离线" : "在线" }}
                </div>
              </div>
            </div>
            <ul class="list-group my-list-group flex-fill">
              <li class="list-group-item text-white">联系人信息 {{ client.email }}</li>
              <li class="list-group-item text-white">
                <div class="form-group d-flex align-items-center my-group mb-0">
                  <label class="flex-fill mb-0">用户名:</label>
                  <input type="text" class="form-control text-white" placeholder="请输入用户名" v-model="client.client_name" disabled />
                </div>
              </li>
              <li class="list-group-item text-white">
                <div class="form-group d-flex align-items-center my-group mb-0">
                  <label class="flex-fill mb-0">邮箱:</label>
                  <input type="text" class="form-control text-white" placeholder="请输入邮箱" v-model="client.email" disabled />
                </div>
              </li>
              <li class="list-group-item text-white" v-for="(data, index) in kv_data" :key="index">
                <div class="form-group d-flex align-items-center my-group mb-0">
                  <label class="flex-fill mb-0">{{ data.key }}:</label>
                  <input type="text" class="form-control text-white" :placeholder="'请输入' + data.key" v-model="data.value" disabled />
                </div>
              </li>
            </ul>
            <div class="card-footer d-flex justify-content-center d-xl-none">
              <div class="btn btn-primary btn-icon" @click="closeMain"><i class="iconfont icon-back"></i></div>
            </div>
          </div>
        </div>
        <div class="flex-fill my-flex card-body" style="flex:2 !important;">
          <div class="my-w">
            <div class="p-2 d-flex align-items-start justify-content-between my-top">
              <div>上次报道的位置</div>
              <div class="dropdown-toggle"></div>
            </div>
            <div class="collapse show p-2 my-collapse">
              <span>城市、国家</span>
              <span>{{ client.loc }}</span>
            </div>
            <div class="collapse show p-2 my-collapse">
              <span>当地时间</span>
              <span>{{ new Date() }}</span>
            </div>
          </div>
          <div class="my-w">
            <div class="p-2 d-flex align-items-start justify-content-between my-top">
              <div>对话 {{ client.email }}</div>
              <div class="dropdown-toggle"></div>
            </div>
            <div class="collapse show p-2 my-collapse contacts-list">
              <li class="contacts-item friends" v-for="data in messageList" :key="data.session_id">
                <a class="contacts-link">
                  <div class="avatar " :class="{ 'avatar-online': data.status == 'online', 'avatar-offline': data.status != 'online' }">
                    <img :src="data.avatar" draggable="false" />
                  </div>
                  <div class="contacts-content">
                    <div class="contacts-info">
                      <h6 class="chat-name text-truncate">{{ data.seat_name }}</h6>
                      <div class="chat-time">{{ data.last_msg_time | humanTime }}</div>
                    </div>
                    <div class="contacts-texts">
                      <p class="text-truncate" v-if="data.last_msg_type == 'html'" v-html="data.last_msg"></p>
                      <p class="text-truncate" v-else>{{ data.last_msg }}</p>
                      <div class="btn  btn-success btn-sm" @click="look(data)">查看会话</div>
                    </div>
                  </div>
                </a>
              </li>
            </div>
          </div>
          <div class="my-w">
            <div class="p-2 d-flex align-items-start justify-content-between my-top">
              <div>私人记事本</div>
              <div class="dropdown-toggle"></div>
            </div>
            <div class="collapse show p-2 my-collapse">
              <textarea rows="10" class="form-control memo-form-control text-muted" style="background: #2d2d2d" placeholder="记录您的私人记事本" v-model="memo"></textarea>
              <div data-v-7f1bc284="" class="btn btn-block btn-success mt-2" @click="sure">保存</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="crm-body d-flex  align-items-start card-body hide-scrollbar"></div>
  </div>
</template>
<script>
import eventBus from "@/utils/eventBus";
import welcome from "@/components/welcome";
import { serMemo } from "@/api/chat";
import { getClientChats } from "@/api/crm";
import md5 from "md5";
import moment from "moment";

export default {
  data() {
    return {
      mainSwitch: false,
      client: {},
      crmCheck: false,
      memo: "",
      messageList: [],
      avatarURL: "https://cdn.v2ex.com/gravatar/",
      URL: process.env.VUE_APP_API_BASE_URL,
      kv_data: "",
    };
  },
  components: {
    welcome,
  },
  filters: {
    humanTime(value) {
      let duration = Date.now() - value * 1000;
      return duration < 86400 * 3 * 1000 ? moment.duration(duration).humanize() : moment(value * 1000).format("YYYY-MM-DD");
    },
  },
  mounted() {
    eventBus.$on("clickCrm", (seat) => {
      this.crmCheck = true;
      eventBus.$emit("mainSwitch", true);
      this.client = seat;
      this.memo = this.client.memo;
      if (this.client.kv_data != "") {
        this.kv_data = JSON.parse(this.client.kv_data);
      } else {
        this.kv_data = this.client.kv_data;
      }
      console.log(seat, "3333333333333333");
      this.getMessageList();
    });
  },
  methods: {
    closeMain() {
      eventBus.$emit("mainSwitch", false);
    },
    look(item) {
      console.log(item);
      this.$router.push({ path: "/chat/" + item.session_id + "/details" });
    },
    getMessageList() {
      getClientChats({ client_id: this.client.client_id }).then((res) => {
        // res.result.forEach((element) => {
        //   if (element.avatar == undefined) {
        //     element.avatar = this.avatarURL + md5(element.email);
        //   } else {
        //     if (element.avatar.indexOf("/") == 0) {
        //       element.avatar = `${this.URL}${element.avatar}`;
        //     }
        //   }
        // });
        this.messageList = res.result;
      });
    },
    avatar(email) {
      return email ? `https://cdn.v2ex.com/gravatar/${md5(email)}` : "https://cdn.v2ex.com/gravatar/default";
    },
    sure() {
      serMemo({ md5Memo: md5(this.client.memo), memo: this.memo, client_id: this.client.client_id }).then((res) => {
        if (res.code == 200) {
          this.$toast.success("添加备注成功", {
            timeout: 4000,
            position: "top-right",
          });
        }
      });
    },
    typeDropdownToggle() {
      this.typeDropdown = !this.typeDropdown;
    },
    onSearch(e) {
      e.preventDefault();
    },
    sendMsg(e) {
      e.preventDefault();
      console.log(e);
    },
  },
};
</script>

<style lang="less">
:root {
  --my-w: 49%;
}
.my-client-avatar {
  min-width: 13px !important;
  width: 13px !important;
  height: 13px !important;
  box-shadow: none !important;
}
.my-row {
  margin: 0px !important;
}
.my-info {
  height: 100%;
  min-width: 21.875rem !important;
}
.my-info .card-body {
  flex: none !important;
}
.my-group .form-control {
  width: 70%;
  background: #383f44 !important;
  border-color: #383f44 !important;
}

.my-list-group .list-group-item {
  border-left: 0px;
  border-right: 0px;
}
.my-list-group .list-group-item:nth-child(1) {
  border-top: 0px;
}
.my-top {
  background: #383f44;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  cursor: pointer;
  color: #adb5bd;
}
.my-collapse {
  background: #2d2d2d;
  border-bottom: 1px solid #333341;
  color: white;
}
.my-collapse span {
  display: inline-block;
}
.my-collapse span:nth-child(1) {
  width: 30%;
}
.my-collapse span:nth-child(2) {
  width: 70%;
}

.my-w {
  margin-bottom: 20px;
}
</style>
